import * as $ from 'jquery';
import 'datatables';

export default (function () {
	if ($('#dataTable').length) {
		$('#dataTable').DataTable({
			language: {
				search: "_INPUT_",
				emptyTable: 'Nessun dato disponibile'
			},
			"bLengthChange": false,
			aaSorting: [],
            "bPaginate": false
		});
	}

	if ($('#dataTableAjax').length) {
		let url = $('#dataTableAjax').data('ajaxurl');
		let sortDefault = $('#dataTableAjax').data('orderby');
		if (typeof(sortDefault) == 'undefined') {
			sortDefault = 0;
		}
		let sortType = $('#dataTableAjax').data('sortType');
		if (typeof(sortType) == 'undefined') {
			sortType = 'desc';
		}
		let columnData = [];
		$('#dataTableAjax').find('th').each(function( index ) {
			let classname = null;
			if (typeof($(this).data('itemclass')) != 'undefined') {
				classname = { className: $(this).data('itemclass')};
			}
			columnData.push(classname);
		});
		$('#dataTableAjax').DataTable({
			'language': {
				'search': '_INPUT_',
				'emptyTable': 'Nessun dato disponibile'
			},
			'bLengthChange': false,
			'aaSorting': [],
			'columns': columnData,
			'processing': false,
			'serverSide': true,
			'serverMethod': 'get',
			'ajax': {
				'url': url,
				beforeSend: function() {
					$('#loader').removeClass('fadeOut');
				},
				complete: function() {
					$('#loader').addClass('fadeOut');
				},
			},
			'paging'      : true,
			'pagingType'  : 'numbers',
			'ordering'    : true,
			'info'        : true,
			'autoWidth'   : false,
			'initComplete': function() {
				if (this.api().page.info().pages === 1) {
					$('.dataTables_paginate').hide();
				}
				$('[data-toggle="tooltip"]').tooltip();
			},
			'order': [[sortDefault, sortType]],
            "bPaginate": false
		});
	}

    if ($('#users_table').length) {
        $('#users_table').DataTable({
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Filtra cliente",
                emptyTable: 'Non ci sono utenti nella tabella'
            },
            aaSorting: [],
            "bPaginate": false
        });
    }
    if ($('#scadenzeTable').length) {
        $('#scadenzeTable').DataTable({
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Filtra cliente",
                emptyTable: "Nessun dato",
            },
            aaSorting: [],
            "bPaginate": false
        });
    }
    if ($('#archivioTable').length) {
        $('#archivioTable').DataTable({
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Filtra cliente",
                emptyTable: "Nessun dato",
            },
            aaSorting: [],
            "bPaginate": false
        });
    }
    if ($('#customers_table').length) {
        $('#customers_table').DataTable({
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Filtra cliente",
                emptyTable: "Nessun dato",
            },
            aaSorting: [],
            "bPaginate": false
        });
    }
}());
