import * as $ from 'jquery';
import Dropzone from 'dropzone';
import 'tooltipster';
import Swal from 'sweetalert2';

export default (function () {
    Dropzone.autoDiscover = false;
    var filesForUpload = Array();
    var templateIndexes = Array();
    $(document).ready(function () {

        $('.form-btn-add-template button').each(function (index) {
            let container = $(this).closest('.template-container');
            templateIndexes[container.attr('id')] = container.find('.template-row').length;
        });

        $(document).on('change', '.form-control-file', function (e) {
            let fileName = e.target.files[0].name;
            let container = $(this).closest('.form-group-file');
            container.find('.form-group-file-label').text(fileName);
            container.find('.file-hidden').val('');
        });

        $('.form-tooltip-select').tooltipster({
            contentCloning: true,
            trigger: 'click',
            side: 'bottom',
            interactive: 'true',
            autoClose: 'false',
            functionReady: function () {
                $('.tooltip-type-option').click(function () {
                    $('.form-tooltip-select').val($(this).text());
                    if (typeof ($(this).data('value')) != 'undefined') {
                        $('#' + $(this).data('element')).val($(this).data('value'));
                    }
                    $('.form-tooltip-select').tooltipster('hide');
                });
                $('.tooltip-type-option-auto input').keypress(function (event) {
                    var keycode = (event.keyCode ? event.keyCode : event.which);
                    if (keycode == '13') {
                        $('.form-tooltip-select').val($(this).val());
                        $('.form-tooltip-select').tooltipster('hide');
                    }
                });
            }
        });

        $(document).on('click', '.form-btn-add-template button', function (e) {
            let container = $(this).closest('.template-container');
            let index = container.find('.template-row').length;
            if (typeof (templateIndexes[container.attr('id')]) != 'undefined') {
                index = templateIndexes[container.attr('id')] + 1;
            }
            templateIndexes[container.attr('id')] = index;

            let template = container.find('.clone-template').html();
            template = template.replace(/%%index%%/gi, index);
            container.find('.form-btn-add-template').before(template);
            if (typeof ($(this).data('callback')) != 'undefined') {
                if ($(this).data('callback') == 'click' && typeof ($(this).data('elementdata')) != 'undefined') {
                    $('[data-element="' + $(this).data('elementdata') + index + '"]').click();
                }
            }
        });

        $(document).on('click', '.form-btn-delete', function (e) {
            let container = $(this).closest('.template-container');
            let index = container.find('.template-row').length;
            if (index <= 1) {
                return;
            }
            $(this).closest('.template-row').remove();
        });

        $(document).on('click', '.js-edit-position', function (e) {
            let params = $(this).data();
            if (typeof (params['modal']) != 'undefined') {
                let modal = $('#' + params['modal']);
                modal.find('input[name="modal_' + params['value'] + '"]').val($('.' + params['classvalue']).val());
                modal.find('input[name="modal_classvalue').val(params['classvalue']);
                $('#' + params['modal']).modal("show");
            }
        });

        $(document).on('click', '.js-modal-submit', function (e) {
            let modal = $(this).closest('.modal');
            let classvalue = modal.find('input[name="modal_classvalue"]').val();
            if (classvalue && typeof (classvalue) != 'undefined') {

                let editElementData = $('[data-classvalue="' + classvalue + '"]').data();
                let elementConteiner = $('.' + classvalue).closest('.form-group');
                let value = '';
                if (typeof (editElementData['value']) != 'undefined') {
                    value = modal.find('input[name="modal_' + editElementData['value'] + '"]').val();
                }
                $('.' + classvalue).val(value);

                if (typeof (editElementData['label']) != 'undefined') {
                    elementConteiner.find('.' + editElementData['label']).text(value);
                }

                if (typeof (editElementData['modal']) != 'undefined') {
                    $('#' + editElementData['modal']).modal("hide");
                }
            }
        });
    });

    if ($('.file-dropzone').length && typeof ($('.file-dropzone').data('url')) != 'undefined') {
        var data = $('.file-dropzone').data();
        data.headers = {
            'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
        }
        data.dictRemoveFile = 'X';
        data.dictCancelUpload = '~';
        if (typeof (data.paramname) != 'undefined' && data.paramname !== '') {
            data.paramName = data.paramname;
            delete data.paramname;
        }
        if (typeof (data.maxfiles) != 'undefined' && data.maxfiles !== '') {
            data.maxFiles = data.maxfiles;
            delete data.maxfiles;
        }
        if (typeof (data.createimagethumbnails) != 'undefined' && data.createimagethumbnails !== '') {
            data.createImageThumbnails = data.createimagethumbnails;
            delete data.createimagethumbnails;
        }
        if (typeof (data.addremovelinks) != 'undefined' && data.addremovelinks !== '') {
            data.addRemoveLinks = data.addremovelinks;
            delete data.addremovelinks;
        }
        if (typeof (data.previewtemplate) != 'undefined' && data.previewtemplate !== '') {
            data.previewTemplate = $('#' + data.previewtemplate).html();
            delete data.previewtemplate;
        }

        if (typeof (data.uploaded) != 'undefined' && data.uploaded !== '') {
            data.init = function () {
                var uploadedFile = {name: data.uploadedname, size: data.uploadedsize, type: data.uploadedtype};
                this.options.addedfile.call(this, uploadedFile);
                this.options.thumbnail.call(this, uploadedFile, data.uploaded);
            }
        }

        data.success = function (file, response) {
            if (typeof (response.error) != 'undefined' && response.error == true) {
                alert(response.message);
                return;
            }
            if (typeof (response.patch) != 'undefined') {
                $('input[name="' + data.paramName + '"]').val(response.patch);
            }
        }

        if (typeof (data.removeurl) != 'undefined' && data.removeurl !== '') {
            data.removedfile = function (file) {
                var name = $('.file-dropzone-hidden').val();
                var token = document.head.querySelector('meta[name="csrf-token"]').content;
                $.ajax({
                    type: 'POST',
                    url: data.removeurl,
                    data: {name: name, _token: token},
                    sucess: function (data) {
                        $('.file-dropzone-hidden').val('');
                    }
                });
                var _ref;
                return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
            }
        }

        var myDrop = new Dropzone(".file-dropzone", data);
    }

    let callbackFunctions = {
        remove_row: (element, response) => {
            element.closest('tr').remove();
        },
        refresh: (element, response) => {
            window.location.reload(true);
        },
        redirect: (element, response) => {
            window.location.href = response.redirect;
        },
        replaces: (element, response) => {
            if (response.replaces instanceof Array) {
                for (var i = 0, ilen = response.replaces.length; i < ilen; i++) {
                    $(response.replaces[i].what).replaceWith(response.replaces[i].data);
                }
            }
        },
        append: (element, response) => {
            if (response.append instanceof Array) {
                for (var i = 0, ilen = response.append.length; i < ilen; i++) {
                    $(response.append[i].what).append(response.append[i].data);
                }
            }
        },
        prepend: (element, response) => {
            if (response.prepend instanceof Array) {
                for (var i = 0, ilen = response.prepend.length; i < ilen; i++) {
                    $(response.prepend[i].data).insertAfter(response.prepend[i].what);
                }
            }
        },
        append_js: (element, response) => {
            if (response.js) {
                $("body").append(response.js);
            }
        },
        addAjaxRow: (element, params) => {
            if (typeof (params['url']) == 'undefined' || typeof (params['container']) == 'undefined') {
                return;
            }
            if (typeof (templateIndexes[params['container']]) == 'undefined') {
                templateIndexes[params['container']] = $(params['container']).find('.template-row').length;
            } else {
                templateIndexes[params['container']] = templateIndexes[params['container']] + 1;
            }
            params['index'] = templateIndexes[params['container']];
            if (typeof (params['required']) != 'undefined') {
                let requiredparam = $('[name="' + params['required'] + '"]').val();
                if (typeof (requiredparam) != 'undefined' && requiredparam != '') {
                    params['required_' + params['required']] = requiredparam;
                }
            }
            let form_data = new FormData();
            for (var key in params) {
                form_data.append(key, params[key]);
            }
            sendAjax(params['url'], 'POST', form_data, $(params['container']));
        },
        send_ajax: (element, params) => {
            let form_data = new FormData();
            for (var key in params) {
                form_data.append(key, params[key]);
            }
            sendAjax(params['url'], 'POST', form_data, element);
        }
    };

    function sendAjax(url, method, data, element) {
        $.ajax({
            url: url,
            method: method,
            data: data,
            processData: false,
            contentType: false,
            beforeSend: function () {
                $('#loader').removeClass('fadeOut');
            }
        }).done(function (response) {
            clearFieldInput(element[0]);
            //getAlert(element[0]);
            let callbackFunc = element.data('callback');
            if (typeof (callbackFunc) == 'undefined' || callbackFunc == '') {
                callbackFunc = response.callbackFunc;
            }
            if (response.errors == false && typeof (callbackFunc) != 'undefined' && callbackFunc != '') {
                callbackFunctions[callbackFunc](element, response);
            }

            if(response.packing){
                let packing = JSON.parse(response.packing);
                console.log('response', response);
                $('#packing-container').empty();
                $('#packing-container').append('' +
                    '<div id="cert-packaging-item" class="text-center indication-item">'+
                    '<div class="overflow-hidden">' +
                    '<img height="47" src="/storage/'+packing['img']+'">' +
                    '<span class="packaging-title">'+packing['label']+'</span></div>' +
                    '<a href="javascript:void(0);" data-id="'+response.id+'" class="packaging-item-delete"><div class="files-delete-border"><i class="ti-close"></i></div></a>' +
                    '<input name="packaging[img]" type="hidden" value="'+packing['img']+'">' +
                    '<input name="packaging[label]" type="hidden" value="'+packing['label']+'">' +
                    '<input name="packaging[name]" type="hidden" value="'+packing['name']+'">' +
                    '<input name="packaging[id]" type="hidden" value="'+packing['id']+'"></div>');
            }

            $('#loader').addClass('fadeOut');
        }).fail(function (response) {
            $(element).find('input').removeClass('is-invalid');
            $(element).find('p.invalid-feedback').remove();
            if (typeof (response.responseJSON.errors) != 'undefined') {
                let allErrors = response.responseJSON.errors;
                for (const property in allErrors) {
                    let elem = $(element).find('input[name="' + property + '"]');
                    console.log(elem[0].dataset.type);
                    if (elem[0].dataset.type === 'file') {
                        console.log('file', elem[0].dataset.type);
                        elem.parent().parent().parent().append('<p class="invalid-feedback display-b">' + allErrors[property] + '</p>');
                    } else {
                        elem.addClass('is-invalid')
                            .after('<p class="invalid-feedback">' + allErrors[property] + '</p>');
                    }

                }
                $('body,html').animate({scrollTop: 0}, 400);
            }
            $('#loader').addClass('fadeOut');
        });
    }

    $('.show-page-files').on('click', function () {
        $(this.dataset.target).find("input[type=text], input[type=file]").val("");
        $(this.dataset.target).find("input[type=text], input[type=file]").removeClass('is-invalid');
        $(this.dataset.target + " .form-group-file-label")[0].innerText = '';
        $(this.dataset.target + " .invalid-feedback").remove();
    });

    function clearFieldInput(elem) {
        if (elem.action) {
            let temp = elem.action.split('/');
            if (temp[3] + '/' + temp[4] === 'documents/upload') {
                elem[6].value = '';
                elem[7].value = '';
                elem[8].value = '';
                let nameClassLabel = '.label_attached_' + elem.type.value;
                $(nameClassLabel)[0].textContent = '';
            }
        }
    }

    function getAlert(elem) {
        if (elem.dataset.url) {
            let temp = elem.dataset.url.split('/');
            if (temp[4]) {
                if (temp[4] === 'updateOneItem') {
                    alert('Modifiche aggiunte');
                }
            }
        }
    }

    let active = 0;
    $(document).on('click', '.aggiungi_client', function (e) {
        active = 1;
        //console.log(active);
    });

    $(document).on('click', '.annulla_documents_upload', function (e) {
        if (this.dataset.type) {
            let elem = $('.form_file_' + this.dataset.type);
            clearFieldInput(elem[0]);
        }
    });


    $(document).ready(function () {
        if (sessionStorage.scrollTop != "undefined") {
            $(window).scrollTop(sessionStorage.scrollTop);
            sessionStorage.removeItem('scrollTop');
        }
    });

    $(document).on('submit', '.ajaxSubmit', function (e) {
        e.preventDefault();

        let _that = $(this);

        let method = $(this).attr('method');
        if (typeof (method) == 'undefined' || method == '') {
            method = 'POST';
        }
        let url = $(this).attr('action');
        let token = $(this).find('[name="_token"]').val();

        let data = new FormData(this);
        data.append('_token', token);
        data.append('active', active);
        if (typeof (url) != 'undefined' && url != '') {
            sessionStorage.scrollTop = $('body,html').scrollTop();
            sendAjax(url, method, data, _that);
        }
        active = 0;
    });

    if (typeof ($('.select2withCallback').data('callbackfunk'))) {
        $(document).on('select2:select', '.select2withCallback', function (e) {
            let params = $(this).data();
            params['value'] = e.params.data.id;
            params['name'] = $(this).attr('name');
            params['_token'] = document.head.querySelector('meta[name="csrf-token"]').content;
            callbackFunctions[params['callbackfunk']]($(this), params);
            if (params['clearval'] == true) {
                $(this).val('').trigger('change');
            }
        });
    }

    $(document).on('click', '.ajaxLink', function (e) {
        e.preventDefault();

        let _that = $(this);
        let method = $(this).data('method');
        if (typeof (method) == 'undefined' || method == '') {
            method = 'POST';
        }
        let url = $(this).attr('href')
        if (typeof (url) == 'undefined') {
            url = $(this).data('url');
        }
        let callbackFunc = $(this).data('callback');
        let data = new FormData();
        if (typeof ($(this).data('params')) != 'undefined') {
            let params = $(this).data('params');
            for (var key in params) {
                data.append(key, params[key]);
            }
        }
        data.append('_token', document.head.querySelector('meta[name="csrf-token"]').content);

        if (method != 'GET' && method != 'POST') {
            data.append('_method', method);
            method = 'POST';
        }

        if (typeof (url) != 'undefined' && url != '') {
            if ($(this).data('confirm') == true) {
                Swal.fire({
                    title: 'Sei sicuro?', // Opération Dangereuse
                    text: 'Sei sicuro di continuare ?', // Êtes-vous sûr de continuer ?
                    type: 'error',
                    icon: 'error',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonColor: 'null',
                    cancelButtonColor: 'null',
                    confirmButtonClass: 'btn-add text-center m-2',
                    cancelButtonClass: 'btn-modal-cancel text-center m-2',
                    confirmButtonText: 'Si certo!', // Oui, sûr
                    cancelButtonText: 'Annulla', // Annuler
                }).then(res => {
                    if (res.value) {
                        sendAjax(url, method, data, _that);
                    }
                });
            } else {
                sendAjax(url, method, data, _that);
            }
        }
    });

    $(document).on('change', '.change-submit-form', function () {
        $(this).closest('form').submit();
    });

    $(document).on('click', '.js-hover-action', function () {
        if (typeof ($(this).data('element')) != 'undefined') {
            $('#' + $(this).data('element')).toggle();
        }
    });

    $(document).on('mouseenter', '.js-hover-action', function () {
        if (typeof ($(this).data('element')) != 'undefined') {
            $('#' + $(this).data('element')).show();
        }
    })
    $(document).on('mouseleave', '.js-hover-action', function () {
        if (typeof ($(this).data('element')) != 'undefined') {
            let elem = $('#' + $(this).data('element'));
            if (elem.is(":hover") == false) {
                setTimeout(function () {
                    elem.hide();
                }, 300);
            }
        }
    });
    $(document).on('mouseleave', '.customers-index-actions', function () {
        $(this).hide();
    });

    $(document).on('click', '.customers-search-link-submit', function () {
        var value = $('input[name="order"]').val();
        if (value == 'ASC') {
            $('input[name="order"]').val('DESC');
        } else {
            $('input[name="order"]').val('ASC');
        }
        $(this).trigger('change');
    });

    function hideDoubledImages() {

        $('.fire-images-container').each(function (index) {

            var _aFireImages = [];

            $(this).find('img').each(function (index) {

                var _src_ = $(this).attr('src');

                if (typeof _aFireImages[_src_] == 'undefined') {
                    _aFireImages[_src_] = index;
                } else {
                    $(this).hide();
                }

                //console.log('zzz');
            });

        });

    }

    hideDoubledImages();

    $(document).on('hidden.bs.modal', '.modal', function () {
        if ($(this).hasClass('modal-cancel-with-scroll')) {
            setTimeout(function () {
                $('body').addClass('modal-open')
            }, 400);
        }


        if ($(this).hasClass('modal-call-after-close')) {

            $('.fire-images-container').each(function (index) {

                var _aFireImages = [];

                $(this).find('img').each(function (index) {

                    var _src_ = $(this).attr('src');

                    if (typeof _aFireImages[_src_] == 'undefined') {
                        _aFireImages[_src_] = index;
                    } else {
                        $(this).hide();
                    }

                    //console.log('aaa');
                });

            });
//updateOneItem искать тут
            if (typeof ($(this).data('callbackfunk')) != 'undefined') {
                let params = $(this).data();
                params['_token'] = document.head.querySelector('meta[name="csrf-token"]').content;
                if (typeof (params['ajax_update']) != 'undefined' && params['ajax_update'] == true) {
                    let modalData = $(this).find('.js-select-label').first().data();
                    if (typeof (modalData['type']) != 'undefined') {
                        $('[name^="' + modalData['type'] + '"]').each(function (index) {
                            let name = $(this).attr('name');
                            params[name] = $(this).val();
                        });
                    }
                }
                if (this.id !== 'indication_popup') {
                    callbackFunctions[params['callbackfunk']]($(this), params);
                }
            }
        }
    });

    $(document).on('change', '.ajax-file-upload', function () {
        let data = new FormData();
        if (typeof ($(this).data('params')) != 'undefined') {
            let params = $(this).data('params');
            for (var key in params) {
                data.append(key, params[key]);
            }
        }
        data.append($(this).attr('name'), $(this)[0].files[0]);
        data.append('_token', document.head.querySelector('meta[name="csrf-token"]').content);

        if (typeof ($(this).data('url')) != 'undefined' && $(this).data('url') != '') {
            sendAjax($(this).data('url'), 'POST', data, $(this));
        }
    });

    $(document).on('change', '.send-field-data', function () {
        let params = $(this).data();
        params[$(this).attr('name')] = $(this).val();
        params['_token'] = document.head.querySelector('meta[name="csrf-token"]').content;
        callbackFunctions['send_ajax']($(this), params);
    });

    $(document).on('change', '.send-group-field-data', function () {
        let params = $(this).data();
        let name = $(this).attr('name');
        let reg = name.match(/(.*)\[(.*)\]\[(.*)\]/);
        if (reg == null) {
            reg = name.match(/(.*)\[(.*)\]/);
            if (reg == null) {
                reg[1] = name;
            }
        }
        $('[name^="' + reg[1] + '"]').each(function (index) {
            if (($(this).attr('type') == 'radio' || $(this).attr('type') == 'checkbox')) {
                if ($(this).prop("checked")) {
                    params[$(this).attr('name')] = $(this).val();
                }
            } else {
                params[$(this).attr('name')] = $(this).val();
            }
        });
        params['_token'] = document.head.querySelector('meta[name="csrf-token"]').content;
        callbackFunctions['send_ajax']($(this), params);
    });

    $(document).on('change', '.send-group-field-data-radio', function () {
        let params = $(this).data();
        let name = $(this).attr('name');
        let reg = name.match(/(.*)\[(.*)\]\[(.*)\]/);
        if (reg == null) {
            reg = name.match(/(.*)\[(.*)\]/);
            if (reg == null) {
                reg[1] = name;
            }
        }
        $('[name^="' + reg[1] + '"]').each(function (index) {
            if (($(this).attr('type') == 'radio' || $(this).attr('type') == 'checkbox')) {
                if ($(this).prop("checked")) {
                    params[$(this).attr('name')] = $(this).val();
                }
            } else {
                params[$(this).attr('name')] = $(this).val();
            }
        });
        params['_token'] = document.head.querySelector('meta[name="csrf-token"]').content;
        callbackFunctions['send_ajax']($(this), params);
    });

    $(document).on('click', '.client-block .indication-item-delete', function () {
        let name = $(this).parent().find('input').first().attr('name');
        let reg = name.match(/(.*)\[(.*)\]\[(.*)\]/);
        let params = Array();
        params['_token'] = document.head.querySelector('meta[name="csrf-token"]').content;
        params['url'] = $('#indication_popup').data('url');
        params['id'] = $('#indication_popup').data('id');
        $(this).closest('.indication-item').remove();
        $('[name^="' + reg[1] + '"]').each(function (index) {
            params[$(this).attr('name')] = $(this).val();
        });
        callbackFunctions['send_ajax']($(this), params);
    });


    $(document).on("click", ".cas-switch .slider", function () {

        if ($('#classificazione_adr_slider').is(':checked')) {

            $('.classificazione_adr_block').addClass('hidenow');
        } else {

            $('.classificazione_adr_block').removeClass('hidenow');
        }
    });

    $(document).on("click", ".delete-select2-item", function () {
        //to do function delete
    });

    $(document).on("click", ".add-new-confezionamento", function () {
        $('#js-packing-select2').select2('close');
    });

    $(document).on("click", ".confezionamento_add_new", function (e) {

        let data = $('#confezionamento-form');
        data = new FormData(data[0]);

        let url = '/addNewConfezionamento';
        let descrizione = $('#descrizione');
        let descrizioneLabel = $('#descrizione-label');
        let immagine = $('#immagine');

        if (descrizione.val() === '') {
            alert('la descrizione è obbligatoria');
        } else if (descrizioneLabel.val() === '') {
            alert('l\'etichetta è obbligatoria');
        } else if (immagine.val() === '') {
            alert('l\'immagine è richiesta');
        } else {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $('#loader').removeClass('fadeOut');
            $.ajax({
                url: url,
                data: data,
                type: 'post',
                processData: false,
                contentType: false,
                success: function (data) {
                    if (data) {
                        $('#add-new-confezionamento').modal("hide");

                        descrizione.val('');
                        descrizioneLabel.val('');
                        immagine.val('');
                        $('.immagine')[0].textContent = '';

                        let newOption = new Option(data.name, data.id, true, false);
                        $("#js-packing-select2").append(newOption).trigger('change');
                        setTimeout(function () {
                            alert('Modifiche aggiunte');
                        }, 200);
                        $('#loader').addClass('fadeOut');
                    }
                },
                error: function (e) {
                    $('#loader').addClass('fadeOut');
                    if (e) {
                        setTimeout(function () {
                            alert('Errore qualcosa è andato storto');
                        }, 200);
                    }
                }
            });
        }
    });

    let idDeleteItem = [];
    $(document).on("click", ".js-add-new-item-in-array .select2-selection", function () {
        let temp = $('.select2-dropdown .add-new-confezionamento');
        if (temp.length === 0) {
            $('.select2-dropdown').append('<a data-toggle="modal" data-target="#add-new-confezionamento" class="add-new-confezionamento">+ Aggiungi</a>');
        }

        let items = $('#select2-js-packing-select2-results li');

        items.each(function (index) {
            if (index !== 0) {
                let id = this.dataset.select2Id.split('-')[6];
                $(this).addClass('item-' + id);
                $(this).after('<div class="files-delete-border delete-select2-item" id="' + id + '" data-id="' + id + '"><i class="ti-close"></i></div>');
            }

        });

        if (idDeleteItem.length > 0) {
            $(idDeleteItem).each(function () {
                let li = $('#select2-js-packing-select2-results .item-' + this);
                let botton = $('#' + this);
                li.remove();
                botton.remove();
            });

        }
    });


    $(document).on("click", ".delete-select2-item", function (e) {
        let _this = $(this);
        e.preventDefault();

        Swal.fire({
            title: 'Sei sicuro?', // Opération Dangereuse
            text: 'Sei sicuro di continuare ?', // Êtes-vous sûr de continuer ?
            type: 'error',
            icon: 'error',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonColor: 'null',
            cancelButtonColor: 'null',
            confirmButtonClass: 'remove-report-pdf text-center m-2',
            cancelButtonClass: 'btn-modal-cancel text-center m-2',
            confirmButtonText: 'Si certo!', // Oui, sûr
            cancelButtonText: 'Annulla', // Annuler
        }).then(res => {
            if (res.value) {
                let id = _this[0].dataset.id;
                _this.closest("form");
                let data = {
                    id: id
                };

                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax({
                    url: '/deleteConfezionamento',
                    data: {id: id},
                    type: 'post',
                    success: function (e) {
                        $('#loader').addClass('fadeOut');
                        if (e) {
                            idDeleteItem.push(id);
                            alert('Cancellato con successo');
                        }
                    },
                    error: function (e) {
                        $('#loader').addClass('fadeOut');
                        if (e) {
                            alert('Errore qualcosa è andato storto');
                        }
                    }
                });
            }
        });
    });

    $(document).on("change", "#file_offer_input", function (e) {

        let id = this.dataset.id;

        let data = $('.file_offer_input_form_' + id);
        data = new FormData(data[0]);

        data.append('id', id);
        data.append('file', true);

        let url = '/fornitori/updateFileItem';

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $('#loader').removeClass('fadeOut');
        $.ajax({
            url: url,
            data: data,
            type: 'post',
            processData: false,
            contentType: false,
            success: function (data) {
                if (data) {
                    $('#add-new-confezionamento').modal("hide");

                    setTimeout(function () {
                        alert('Modifiche aggiunte');
                    }, 200);
                    $('#loader').addClass('fadeOut');
                    location.reload();
                }
            },
            error: function (e) {
                $('#loader').addClass('fadeOut');
                if (e) {
                    setTimeout(function () {
                        alert('Errore qualcosa è andato storto');
                    }, 200);
                    location.reload();
                }
            }
        });

    });
    $(document).on("click", ".conferma_versione_upload", function (e) {
        e.preventDefault();
        let id = this.dataset.id;

        let data = $('#versione_form');
        data = new FormData(data[0]);

        let url = '/documents/upload';

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $('#loader').removeClass('fadeOut');
        $.ajax({
            url: url,
            data: data,
            type: 'post',
            processData: false,
            contentType: false,
            success: function (data) {
                if (data) {
                    $('#add-new-confezionamento').modal("hide");

                    setTimeout(function () {
                        alert('Modifiche aggiunte');
                    }, 200);
                    $('#loader').addClass('fadeOut');
                    location.reload();
                }
            },
            error: function (e) {
                $('#loader').addClass('fadeOut');
                if (e) {
                    setTimeout(function () {
                        alert('Errore qualcosa è andato storto');
                    }, 200);
                    location.reload();
                }
            }
        });

    });

}());
