import moment from 'moment';
import * as $ from "jquery";
import Swal from "sweetalert2";

$(document).ready(function () {
    if (localStorage.getItem('isCollapsed')) {
        $('.app').addClass('is-collapsed')
    } else {
        $('.app').removeClass('is-collapsed')
    }

    let fase = ['Screening', 'Pianificazione', 'Partenza', 'Verifaca/Audit', 'Mantenimento/Aggiornamento'];

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    });

    $(document).on('input', '.text-area-form-descrizione', function () {
        var totalHeight = $(this).prop('scrollHeight');
        $(this).css({'height': totalHeight});
    });

    $(".modulo_screening").on('click', function () {

        $('#agguingi_varbale').modal("hide");

        setTimeout(function () {
            $('#modulo_screening').modal('show');
        }, 600);

    });

    $(document).on('click', '[data-toggle="modal"]', function () {
        if (typeof ($(this).data('remote')) != 'undefined') {
            $($(this).data('target') + ' .modal-body').load($(this).data('remote'));
        }
    });

    $(document).on('click', '.indication-item-delete', function () {
        $(this).closest('.indication-item').remove();
    });

    function alertNullCharacteristic() {
        let lengthIndication = $('#indication-container .indication-item').length
        let lengthCharacteristic = $('#characteristic-container .indication-item').length
        if (lengthIndication > 0 && lengthCharacteristic === 0) {
            alert('Attenzione ci sono ancora degli indici di pericolo!');
        }
    }

    function deleteLabelItem(val, flag = true) {
        let mark = val.dataset;
        let container = $('#' + mark.marker + '-container');
        let items = container.find('.indication-item');

        if (mark.marker === 'indication') {
            //console.log('indication-items', items)
            //удаляем все элементы у которых есть такая картинка
            removeMatchedItemsInHIndication(mark);
            removeMatchedItem(items, mark, 'img', 'img');

            setTimeout(function () {
                addRelatedItems('h-indication');
            }, 300);

        } else if (mark.marker === 'h-indication') {
            //удаляем элиент из болка
            removeMatchedItem(items, mark, 'text', 'text');
            items = container.find('.indication-item');

            let markImg = checkMatchedItems(items, mark, 'img');
            let markCharacteristic = checkMatchedItems(items, mark, 'name');

            if (!markImg) {
                //если нет больше элементов H с такой картинкой удаляем картинку из блока
                let containerIndication = $('#indication-container');
                let containerIndicationItem = containerIndication.find('.indication-item');
                removeMatchedItem(containerIndicationItem, mark, 'img', 'img');
            }

            if (!markCharacteristic) {
                //если нет больше элементов H с такой Characteristic удаляем Characteristic из блока
                let containerIndication = $('#characteristic-container');
                let containerIndicationItem = containerIndication.find('.indication-item');
                removeMatchedItem(containerIndicationItem, mark, 'name', 'text');
                alertNullCharacteristic();
            }


        }
        if (mark.marker === 'adr_label') {
            //удаляем все элементы у которых есть такая картинка
            removeMatchedItem(items, mark, 'img', 'img');
        } else {
            removeMatchedItem(items, mark, 'text', 'text');
            alertNullCharacteristic();
        }
    }

    function checkMatchedItems(items, marks, flag) {
        let temp = false;
        items.each(function (index, value) {
            if (marks[flag] === value.dataset[flag]) {
                //console.log(index, value.dataset)
                temp = true;
            }
        });
        return temp;
    }

    function removeMatchedItem(items, mark, flag1, flag2) {
        items.each(function (index, value) {
            if (mark[flag1] === value.dataset[flag2]) {
                value.remove();
                return true;
            }
        });
    }

    function removeMatchedItemsInHIndication(mark) {

        let hContainerIndication = $('#h-indication-container');
        let items = hContainerIndication.find('.indication-item');
        items.each(function (index, value) {
            if (mark.img === value.dataset.img) {
                value.remove();
            }
        });
    }


    //delete label_item in label_item.blade.php and label_item_frasi_h.blade.php (modal class aggiungi-rifiut-popup)
    $(document).on('click', '.aggiungi-rifiut-popup .cer-indication-item-delete', function () {
        let mark = this.dataset;
        let container = $('#' + mark.marker + '-container');
        let items = container.find('.indication-item');

        if (mark.marker === 'indication') {
            //удаляем элиент из болка
            removeMatchedItem(items, mark, 'text', 'text');
            items = container.find('.indication-item');

            let markCharacteristic = checkMatchedItems(items, mark, 'name');

            if (!markCharacteristic) {
                //если нет больше элементов H с такой Characteristic удаляем Characteristic из блока
                let containerIndication = $('#characteristic-container');
                let containerIndicationItem = containerIndication.find('.indication-item');
                removeMatchedItem(containerIndicationItem, mark, 'name', 'text');
                alertNullCharacteristic();
            }

        }
        if (mark.marker === 'adr_label') {
            //удаляем все элементы у которых есть такая картинка
            removeMatchedItem(items, mark, 'img', 'img');
        } else {
            removeMatchedItem(items, mark, 'text', 'text');
            alertNullCharacteristic();
        }
    });


    $(document).on('click', '.client-block .cer-indication-item-delete', function () {
        let mark = this.dataset.marker;
        let item = this;
        setTimeout(function () {
            deleteLabelItem(item);
        }, 200)

    });


    $(document).on('click', '.packaging-item-delete', function () {
        let id = this.dataset.id;
        let item = $(this);
        $('#loader').removeClass('fadeOut');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/clienticer/packingDelete',
            data: {id: id},
            type: 'post',
            success: function (e) {
                item.closest('.indication-item').removeClass('indication-item').empty();
                $('.js-packing-id').val('');
                $('.js-packing-id').trigger('change');
                $('#loader').addClass('fadeOut');
            },
            error: function (e) {
                $('#loader').addClass('fadeOut');
                alert(e.toString());
            }
        });
    });

    var labelIndexes = Array();

    $('.label-items-container').each(function (index) {
        labelIndexes[$(this).attr('id')] = $(this).find('.indication-item').length;
    });

    let si_certo_frasi_HP = '';
    $(document).on('click', '.si_certo_frasi_HP', function () {
        $('#frasi_HP_popup').modal('hide');
        setTimeout(function () {
            jsSelectLabel(si_certo_frasi_HP);
        }, 700);

    });

    //клик в попапе выбора главная страница
    $(document).on('click', '.first-page-client .js-select-label', function () {
        let data = $(this).data();
        if (data['type'] === 'characteristic') {
            $('#frasi_HP_popup').modal('show');
            si_certo_frasi_HP = this;
        } else {
            jsSelectLabelPopup(this);
        }
    });


    //клик в попапе выбора страница редактирования
    $(document).on('click', '.second-page-client .js-select-label', function () {
        let data = $(this).data();
        if (data['type'] === 'characteristic') {
            $('#frasi_HP_popup').modal('show');
            si_certo_frasi_HP = this;
        } else {
            jsSelectLabel(this);
        }
    });

    function jsSelectLabelPopup(thisData) {
        let data = $(thisData).data();
        let flag = true;
        let id = 0;
        if (typeof (data['type']) == 'undefined' || typeof (data['img']) == 'undefined' || typeof (data['text']) == 'undefined') {
            return;
        }
        if (data['type'] === 'indication') {
            console.log(data['type'], data)
            //проверка на одинаоквые данные в indication
            flag = checkForDuplicates(data, 'text', 'text');

            if (flag) {
                id = getSelectLabel(data);
                //проверка на одинаоквые данные в characteristic
                let newData = {
                    "type": "characteristic",
                    "text": data.name,
                }
                flag = checkForDuplicates(newData, 'text', 'text');
                if (flag) {
                    addBunchFrase(data, id);
                }
            }
        }

        if (data['type'] === 'characteristic') {
            flag = checkForDuplicates(data, 'text', 'text');
            if (flag) {
                id = getSelectLabel(data);
                //addBunchFrase(data, id);
            }
        }

        if (data['type'] === 'adr_label') {
            flag = checkForDuplicates(data, 'img', 'img');
            if (flag) {
                getSelectLabel(data);
            }
        }

        let modal = $(thisData).closest('.modal');
        if (typeof (modal) != 'undefined') {
            modal.modal('hide');
        }
    }

    //проверить и добавить новые данные
    function jsSelectLabel(thisData) {
        let data = $(thisData).data();
        let flag = true;
        if (typeof (data['type']) == 'undefined' || typeof (data['img']) == 'undefined' || typeof (data['text']) == 'undefined') {
            return;
        }
        let id = 0;
        if (data['type'] === 'indication') {
            let data_h = Object.assign({}, data);
            data_h['type'] = 'h-indication';
            //проверка на одинаоквые данные в h-indication
            let flagH = checkForDuplicates(data_h, 'text', 'text');
            if (flagH) {

                //добавляем елемент
                getSelectLabel(data_h);

                //проверка на одинаоквые данные в indication
                flag = checkForDuplicates(data, 'img', 'img');

                if (flag) {
                    id = getSelectLabel(data);
                }

                //проверка на одинаоквые данные в characteristic
                let newData = {
                    "type": "characteristic",
                    "text": data.name,
                }
                flag = checkForDuplicates(newData, 'text', 'text');
                if (flag) {
                    addBunchFrase(data, id);
                }
            }
        }

        if (data['type'] === 'characteristic') {
            //проверка на одинаоквые данные в characteristic
            flag = checkForDuplicates(data, 'text', 'text');
            if (flag) {
                id = getSelectLabel(data);
                //addBunchFrase(data, id);
            }
        }

        if (data['type'] === 'adr_label') {
            flag = checkForDuplicates(data, 'img', 'img');
            if (flag) {
                getSelectLabel(data);
            }
        }

        let modal = $(thisData).closest('.modal');
        if (typeof (modal) != 'undefined') {
            modal.modal('hide');
        }
    }

    function addBunchFrase(data, id) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/addBunchFrase',
            data: data,
            type: 'post',
            success: function (e) {
                if (e) {
                    if (e.text !== null) {

                        if (e['type'] === 'indication') {
                            if (e['text'] != '') {
                                let data_h = Object.assign({}, e);
                                data_h['type'] = 'h-indication';
                                getSelectLabel(data_h);
                            }
                        }

                        ///тут изменить в место id надо добавлять название h
                        getSelectLabel(e, id);
                    }
                }
            },
            error: function (e) {
                if (e) {
                    // setTimeout(function () {
                    //     alert('Errore qualcosa è andato storto');
                    // }, 700);
                }
            }
        });
    }

    //добавить данные на сервер
    function addRelatedItems(name) {

        let container = $('#' + name + '-container');
        let item = container.find('.indication-item');
        let params = Array();
        params['id'] = $('#indication_popup').data('id');

        let temp = 0;
        item.each(function (index) {
            let input = $(this).find('input');
            input.each(function (i) {
                params[this.name] = this.value;
                temp++;
            });
        });

        if (temp === 0) {
            if (name === 'h-indication') {
                name = 'indication';
            }
            params[name] = true;
        }

        let data = new FormData();
        for (let key in params) {
            data.append(key, params[key]);
        }

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            url: '/clienticer/updateOneItem',
            data: data,
            type: 'post',
            processData: false,
            contentType: false,
            success: function (e) {
                //console.log(e);
            },
            error: function (e) {
                setTimeout(function () {
                    alert(e);
                }, 700);
            }
        });
    }

// Долго искал, пока не нашел это дерьмо
// -> <div class="label-items-container" id="indication-container">
// добавить новый шаблон в блок
    function getSelectLabel(data, id = null) {
        let container = $('#' + data['type'] + '-container');
        let item = '';
        if (data['type'] == 'h-indication') {
            item = $('#h-indication-container .indication-item');
        } else {
            item = container.find('.indication-item');
        }
        let index = 'indication_' + getRandomInt(10, 10000);

        labelIndexes[container.attr('id')] = index;

        let template = $('.clone-template-' + data['type']).html();
        if (template) {
            let imgUrl = '';
            if (data.img.length > 0) {
                imgUrl = '/storage/' + data.img;
            }
            if (data['danger'] && data['danger'].length > 0) {
                template = template.replace(/%%danger%%/gi, data['danger']);
            }
            //console.log('data', data)
            template = template.replace(/%%index%%/gi, index);
            template = template.replace(/%%img%%/gi, data['img']);
            template = template.replace(/%%text%%/gi, data['text']);
            template = template.replace(/%%name%%/gi, data['name']);
            template = template.replace(/%%imgurl%%/gi, imgUrl);

            container.append(template);

            return index;
        }
    }

    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    //проверка на дубликаты
    function checkForDuplicates(data, field1, field2) {
        let container = $('#' + data['type'] + '-container');
        let item = container.find('.indication-item');
        let temp = true;
        item.each((i, elem) => {
            if (elem.dataset[field1] === data[field2]) {
                temp = false;
                return true;
            }
        });
        return temp;
    }

    function observeContainer(containerId, callback) {
        const container = document.getElementById(containerId);
        if (!container) return;

        const observer = new MutationObserver(() => {
            // console.log(`#${containerId} modified`);
            callback();
        });

        observer.observe(container, {
            childList: true,
            subtree: true,
            attributes: true,
        });
    }

    observeContainer("characteristic-container", () => addRelatedItems('characteristic'));
    observeContainer("h-indication-container", () => addRelatedItems('h-indication'));
    observeContainer("adr_label-container", () => addRelatedItems('adr_label'));

    // $(document).on('DOMSubtreeModified', "#characteristic-container", function () {
    //     console.log('#characteristic-container')
    //     addRelatedItems('characteristic');
    // });
    //
    // $(document).on('DOMSubtreeModified', "#h-indication-container", function () {
    //     console.log('#h-indication-container')
    //     addRelatedItems('h-indication');
    // });
    //
    // $(document).on('DOMSubtreeModified', "#adr_label-container", function () {
    //     console.log('#adr_label-container')
    //     addRelatedItems('adr_label');
    // });

    $(document).on('click', '.link-from-anything', function () {
        if (typeof ($(this).data('url')) == 'undefined') {
            return;
        }
        window.location.href = $(this).data('url');
    });


    $('#reset-email').on('submit', function (e) {
        e.preventDefault();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $('#loader').removeClass('fadeOut');
        $.ajax({
            url: '/password/email',
            data: $('#reset-email').serialize(),
            type: 'post',
            success: function (e) {
                if (e) {
                    $('#recovery-pass').modal("hide");
                    $('#email')[0].value = '';

                    setTimeout(function () {
                        alert('Ti abbiamo inviato una mail sulla tua casella di posta.');
                    }, 700);
                    $('#loader').addClass('fadeOut');
                    //console.log(e)
                }
            },
            error: function (e) {
                $('#loader').addClass('fadeOut');
                if (e) {
                    $('#recovery-pass').modal("hide");
                    $('#email')[0].value = '';

                    setTimeout(function () {
                        alert('Errore qualcosa è andato storto');
                    }, 700);
                    console.log(e)
                }
            }
        });
    });

    /*$('#codici-smaltimento').off();
    $(document).on('change', '#codici-smaltimento', function() {


        var _option_text = $(this).find('option[value="' + $(this).val() + '"]').text();
        var _current_value = $(this).val();

        if ($('#codici-smaltimento-container-real').length == 0) {

            $('<span id="codici-smaltimento-container-real">'+_option_text+'</span>').insertAfter('#codici-smaltimento');

        }

        if (_option_text != '') {

            $('#select2-codici-smaltimento-container').text(_option_text);
            $('#codici-smaltimento').data('currentvalue', _current_value);

        }
        else {

            console.log('$(this).data(currentvalue)');
            console.log($(this).data('currentvalue'));

            $('#codici-smaltimento').val($(this).data('currentvalue'));

            console.log('this final value');
            console.log($('#codici-smaltimento').val());
        }

    });*/

//  pdf report functionality

    $('#aggiungi_utente_annulla_btn').on('click', function (e) {

        setTimeout(function () {
            $('#agguingi_varbale').modal('show');
        }, 700);
    });

    let oldFasePdf = '';

    $("#crea_nuovo_verbale").on('click', function () {

        if (checkRequired()) {
            $('#modulo_screening').modal("hide");
            $('#agguingi_varbale').modal("hide");


            getReportData();

            setTimeout(function () {
                $('#crea_nuovo').modal('show');
            }, 700);
        } else {
            $('#crea_nuovo_verbale')[0].disabled = true;
        }

    });

    $('#data_documento').on('change', function (e) {
        checkRequiredData();
    });

    $('#date_sopralluogo').on('change', function (e) {
        checkRequiredData();
    });

    $('#time_sopralluogo').on('change', function (e) {
        checkRequiredData();
    });

    $('#date_rossimo_incontro').on('change', function (e) {
        checkRequiredData();
    });

    $('#time_rossimo_incontro').on('change', function (e) {
        checkRequiredData();
    });


    $('#name_file').on('change', function (e) {
        checkRequiredData();
    });

    function checkRequired() {
        return !!($('#data_documento')[0].value
            && $('#date_sopralluogo')[0].value
            && $('#time_sopralluogo')[0].value
            && $('#date_rossimo_incontro')[0].value
            && $('#time_rossimo_incontro')[0].value
            && $('#name_file')[0].value);
    }

    function checkRequiredData() {
        $('#crea_nuovo_verbale')[0].disabled = !checkRequired();
    }

    function readURL(input) {
        if (input.files && input.files[0]) {
            let reader = new FileReader();

            reader.onload = function (e) {
                $('#img-preview').attr('src', e.target.result);
            }

            reader.readAsDataURL(input.files[0]); // convert to base64 string
        }
    }

    $("#planimetria").change(function () {
        readURL(this);
    });

    function dataTransfer() {
        let agguingi_varbale = $('.popup1 .agguingi_varbale-checkbox-row .agguingi_varbale-item');
        let criticita = $('.popup1 .criticita-checkbox-row .agguingi_varbale-item');
        let seleziona_fase = $('#seleziona-fase')[0].value;

        checkSelezionaFase(seleziona_fase);

        if (seleziona_fase === 'Mantenimento/Aggiornamento') {
            seleziona_fase = 'Man/Agg';
        }

        let name_file = $('#name_file')[0].value;
        let data_documento = $('#data_documento')[0].value;
        let note = $('#note')[0].value;
        let importante = $('#importante')[0].value;
        let date_sopralluogo = $('#date_sopralluogo')[0].value;
        let time_sopralluogo = $('#time_sopralluogo')[0].value;
        let date_rossimo_incontro = $('#date_rossimo_incontro')[0].value;
        let time_rossimo_incontro = $('#time_rossimo_incontro')[0].value;
        let time_seleziona = $('#time_seleziona')[0].value;
        let note_attivita = '';

        if (oldFasePdf.length) {
            note_attivita = oldFasePdf[oldFasePdf.length - 1].note_attivita_svolte;
        }

        $('.data_documento_pdf').each(function (i) {
            this.textContent = '';
        });
        $('.data_documento_pdf').append(moment(data_documento).format('DD/MM/YYYY'));

        $('.data_documento_pdf_fase').each(function (i) {
            this.textContent = '';
        });

        fase.forEach(function (index) {
            $('#name_documento_pdf_' + index.replace(/\//, '')).each(function (i) {
                this.textContent = '';
            });
            $('#data_documento_pdf_' + index.replace(/\//, '')).each(function (i) {
                this.textContent = '';
            });
            for (let i in oldFasePdf) {
                if (index === oldFasePdf[i].fase) {
                    $('#data_documento_pdf_' + oldFasePdf[i].fase.replace(/\//, '')).append('<div>' + moment(oldFasePdf[i].date_document).format('DD/MM/YYYY') + '</div>');
                    $('#name_documento_pdf_' + oldFasePdf[i].fase.replace(/\//, '')).append('<div>' + oldFasePdf[i].name + '.pdf</div>');
                }
            }
        });

        if (data_documento !== '') {
            $('#data_documento_pdf_' + seleziona_fase.replace(/\//, '')).append('<div>' + moment(data_documento).format('DD/MM/YYYY') + '</div>');
        } else {
            $('#data_documento_pdf_' + seleziona_fase.replace(/\//, '')).append('<div>nessuna data</div>');
        }
        if (name_file !== '') {
            $('#name_documento_pdf_' + seleziona_fase.replace(/\//, '')).append('<div>' + name_file + '.pdf</div>');
        } else {
            $('#name_documento_pdf_' + seleziona_fase.replace(/\//, '')).append('<div>nessun nome</div>');
        }

        $('.seleziona-fase_pdf').each(function (i) {
            this.textContent = '';
        });
        $('.seleziona-fase_pdf').append(seleziona_fase);

        $('.criticita_da_risolvere').children().remove();
        if (criticita.length > 0) {
            for (let i = 0; i < criticita.length; i++) {
                if (seleziona_fase === criticita[i].children[1].innerText) {
                    $('.criticita_da_risolvere').append('<div class="font-bold-6 padding-l-25"><span class="padding-r-5"></span>' + criticita[i].children[0].innerText + '</div>');
                }
            }
        } else {
            $('.criticita_da_risolvere').append('<div class="font-bold-6 padding-l-25">Non ci sono dati</div>');
        }

        $('.attivita_svolte-block-pgf').children().remove();

        if (agguingi_varbale.length > 0) {
            for (let i = 0; i < agguingi_varbale.length; i++) {
                if (seleziona_fase === agguingi_varbale[i].children[1].innerText) {
                    $('.attivita_svolte-block-pgf').append('<div class="font-bold-6 padding-l-25"><span class="padding-r-5"></span>' + agguingi_varbale[i].children[0].innerText + '</div>');
                }
            }
        } else {
            $('.attivita_svolte-block-pgf').append('<div class="font-bold-6 padding-l-25">Non ci sono dati</div>');
        }

        /*        if (agguingi_varbale.length > 0) {

                    let agguingi_varbales = [];

                    fase.forEach(function (index) {
                        let newItemObject = [];
                        agguingi_varbale.each(function (index_variables) {
                            if (index === this.children[1].textContent)
                                newItemObject[index_variables] = this.children[0].children[1].textContent;
                        });
                        if (Object.keys(newItemObject).length !== 0) {
                            agguingi_varbales[index] = newItemObject;
                        }
                    });

                    for (let index in agguingi_varbales) {
                        $('.attivita_svolte-block-pgf').append('<div class="attivita_svolte_name_fase">' + index + '</div>');
                        let num = 1;
                        agguingi_varbales[index].forEach(function (index) {
                            $('.attivita_svolte-block-pgf').append('<div class="font-bold-6 padding-l-25"><span class="padding-r-5">' + (num++) + ')</span>' + index + '</div>');
                        });
                    }


                } else {
                    $('.attivita_svolte-block-pgf').append('<div class="font-bold-6 padding-l-25">Non ci sono dati</div>');
                }*/

        $('.new-planning-phase').children().remove();
        if (time_seleziona == seleziona_fase) {
            $('.new-planning-phase').append('<div class="font-normal padding-l-25"><span class="padding-r-5">1)</span> PROSEGUIREMO/ULTIMEREMO LA FASE DI ' +
                seleziona_fase + '</div>');
        } else {
            $('.new-planning-phase').append('<div class="font-normal padding-l-25"><span class="padding-r-5">1)</span> RITENIAMO CONCLUSA LA FASE DI ' + seleziona_fase + '</div>');
            $('.new-planning-phase').append('<div class="font-normal padding-l-25"><span class="padding-r-5">2)</span> INIZIEREMO la FASE DI ' + time_seleziona +
                ' iniziando ad utilizzare gli imballaggi idonei</div>');
        }

        $('#importante_pdf')[0].textContent = '';
        $('#importante_pdf')[0].textContent = importante;

        $('#note_pdf')[0].textContent = '';
        $('#note_pdf')[0].textContent = note;

        if (oldFasePdf.length) {
            $('.note-attivita textarea').val(note_attivita);
        }

        $('.date_sopralluogo_pdf').each(function (i) {
            this.textContent = '';
        });
        $('.date_sopralluogo_pdf').append(moment(date_sopralluogo).format('DD/MM/YYYY'));

        $('.time_sopralluogo_pdf').each(function (i) {
            this.textContent = '';
        });
        $('.time_sopralluogo_pdf').append(time_sopralluogo);

        $('.date_rossimo_incontro_pdf').each(function (i) {
            this.textContent = '';
        });
        $('.date_rossimo_incontro_pdf').append(moment(date_rossimo_incontro).format('DD/MM/YYYY'));

        $('.time_rossimo_incontro_pdf').each(function (i) {
            this.textContent = '';
        });
        $('.time_rossimo_incontro_pdf').append(time_rossimo_incontro);

    }

    function checkSelezionaFase(selezionaFase) {
        switch (selezionaFase) {
            case 'Screening':
                $('#Screening').show();
                $('#Pianificazione').hide();
                $('#Partenza').hide();
                $('#Mantenimento').hide();
                break;
            case 'Pianificazione':
                $('#Pianificazione').show();
                $('#Screening').hide();
                $('#Partenza').hide();
                $('#Verifaca').hide();
                $('#Mantenimento').hide();
                break;
            case 'Partenza':
                $('#Partenza').show();
                $('#Screening').hide();
                $('#Pianificazione').hide();
                $('#Verifaca').hide();
                $('#Mantenimento').hide();
                break;
            case 'Mantenimento/Aggiornamento':
                $('#Mantenimento').show();
                $('#Screening').hide();
                $('#Pianificazione').hide();
                $('#Partenza').hide();
                break;
        }
    }

    function getReportData() {
        let client = (document.location.pathname);
        let clientId = client.split('/')[2];

        $('#loader').removeClass('fadeOut');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/getReportData/' + clientId,
            data: {},
            type: 'get',
            success: function (res) {
                $('#loader').addClass('fadeOut');
                oldFasePdf = res;
                dataTransfer();
            },
            error: function (e) {
                $('#loader').addClass('fadeOut');
            }
        });
    }

    $('#nuovo_verbale').on('click', function (e) {
        e.preventDefault();
        sendReportPDF(1);
    });
    $('#salva_in_bozza').on('click', function (e) {
        e.preventDefault();
        sendReportPDF(0);
    });

    //remove btn
    $(document).on('click', '.remove-item', function () {
        let remove = '.' + $(this)[0].dataset.remove;
        $(remove).remove();
    });

    $(document).on('click', '#esporta-input-review', function (e) {
        e.preventDefault();
    });

    $(document).on('click', '#esporta-input-review', function (e) {
        e.preventDefault();
    });

    $(document).on('change', '#esporta-input-review', function (e) {
        let input = $(this).val();

        if (!input.trim().length) {
            return true;
        }

        let href = $('#esporta-tabella-screening').attr('href');
        if (href.includes('?review=')) {
            let arr = href.split('=');
            if (arr.length > 1) {
                arr.pop();
                href = arr + '=' + input;
            } else {
                href = arr + '=' + input;
            }
        } else {
            href += '?review=' + input;
        }

        $('#esporta-tabella-screening').attr('href', href);
    });

    function sendReportPDF(active) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $('#loader').removeClass('fadeOut');
        let client = (document.location.pathname);
        let clientId = client.split('/')[2];
        let agguingi_varbale = $('.popup2 .agguingi_varbale-checkbox-row .agguingi_varbale-item');
        let agguingi_varbale_arr = [];
        if (agguingi_varbale.length > 0) {
            for (let i = 0; i < agguingi_varbale.length; i++) {
                agguingi_varbale_arr.push(
                    {
                        'text': agguingi_varbale[i].children[0].innerText,
                        'phase': agguingi_varbale[i].children[1].innerText,
                    }
                );
            }
        }
        let criticita = $('.popup2 .criticita-checkbox-row .agguingi_varbale-item');
        let criticita_arr = [];
        if (criticita.length > 0) {
            for (let i = 0; i < criticita.length; i++) {
                criticita_arr.push(
                    {
                        text: criticita[i].children[0].innerText,
                        phase: criticita[i].children[1].innerText,
                    }
                );
            }
        }

        let clientReferents = $('.client-referents .client_referente-item');

        let activeClientReferents = [];
        if (clientReferents.length > 0) {
            for (let i = 0; i < clientReferents.length; i++) {
                activeClientReferents.push($(clientReferents[i]).data('name'));
            }
        }

        let params = {
            agguingi_varbale: JSON.stringify(agguingi_varbale_arr),
            criticita: JSON.stringify(criticita_arr),
            seleziona_fase: $('#seleziona-fase')[0].value,
            name_file: $('#name_file')[0].value,
            data_documento: $('#data_documento')[0].value,
            note: $('#note')[0].value,
            importante: $('#importante')[0].value,
            termine_lavori_date: $('#date_sopralluogo')[0].value,
            termine_lavori_time: $('#time_sopralluogo')[0].value,
            prossimo_incontro_date: $('#date_rossimo_incontro')[0].value,
            prossimo_incontro_time: $('#time_rossimo_incontro')[0].value,
            time_seleziona: $('#time_seleziona')[0].value,
            note_attivita_svolte: $('#note-attivita').val(),
            valutati_confermati_le_schede: $('#valutati-confermati-le-schede').val(),
            valutati_confermati_stati: $('#valutati-confermati-stati').val(),
            identificati_confermati: $('#identificati-confermati').val(),
            active_client_referents: JSON.stringify(activeClientReferents),
            iniziato_ultimato: $('#iniziato-ultimato').val(),
            definito_confermato: $('#definito-confermato').val(),
            iniziato_proseguito_ultimato: $('#iniziato_proseguito_ultimato').val(),
            active: active
        };

        let form = $('#image-form');

        let data = new FormData(form[0]);
        for (let key in params) {
            data.append(key, params[key]);
        }

        let zona_pianificazione = {};

        $(".zona").each(function () {
            zona_pianificazione[$(this).attr('name')] = $(this).val();
            data.append($(this).attr('name'), $(this).val());
        });

        data.append('zona_pianificazione', JSON.stringify(zona_pianificazione));

        let url = '/scereeningPdf/' + clientId;
        $.ajax({
            url: url,
            data: data,
            type: 'post',
            processData: false,
            contentType: false,
            success: function (e) {
                if (e.success) {
                    $('#loader').addClass('fadeOut');
                    $('#crea_nuovo').modal('hide');
                    if (e.allReport) {
                        createNewArchivio(e.allReport);
                        createNewPianificazione(e.allReport);
                        updateAggiungiVerbaleBlock(e.allReport);
                        clearAggiungiVerbaleModal();
                        setLatestPhase(e.allReport);
                    }
                    setTimeout(function () {
                        alert(e.success);
                    }, 1000);
                } else {
                    alert(e.errors);
                }
            },
            error: function (e) {
                $('#loader').addClass('fadeOut');
                if (e) {
                    alert(e.responseJSON.errors);
                }
            }
        });
    }

    // add zona pianificazione
    $(document).on('click', '#addZonaPianificazioneTableRow', function () {
        let rowsCount = $('.zona-pianificazione-row').length;

        if (rowsCount === 4) {
            return true;
        }

        let index = ++rowsCount;
        let tr = $(document.createElement('tr')).attr('id', 'zonaPianificazioneRow_' + index).addClass('zona-pianificazione-row');

        let zonaColumn = $(document.createElement('td')).append($('<input>').attr({
            type: 'text',
            class: 'w-100 zona',
            name: 'zona_' + index
        }));
        let descrizioneColumn = $(document.createElement('td')).append($('<input>').attr({
            type: 'text',
            class: 'w-100 zona',
            name: 'descrizione_' + index
        }));
        let rifiutoColumn = $(document.createElement('td')).append($('<input>').attr({
            type: 'text',
            class: 'w-100 zona',
            name: 'rifiuto_' + index
        }));
        let removeColumn = $(document.createElement('td')).append($('<i>').attr({
            class: 'ti-close remove-item',
            'data-remove': 'zona-pianificazione-row-' + index
        }));

        $(tr).append(zonaColumn).append(descrizioneColumn).append(rifiutoColumn).append(removeColumn);

        $("#zonaPianificazioneTable tbody").append(tr);

        let rows = $('#zonaPianificazioneTable tbody tr');

        $(rows).each(function (index) {
            let key = ++index;
            $(this).attr('id', 'zonaPianificazioneRow_' + key);

            let inputs = $(this).find('input[type=text]');

            $(inputs).each(function () {
                let name = $(this).attr('name').split('_');
                let latestIndex = name.pop();
                name.push(key);
                $(this).attr('name', name.join('_'))
            });
        });
    });

    $(document).on('click', '#zonaPianificazioneTable .remove-item', function () {

        let index = $(this).attr('data-remove').split('-').pop();
        let rowsCount = $('.zona-pianificazione-row').length;
        if (rowsCount > 1) {
            $('#zonaPianificazioneRow_' + index).remove();

            let rows = $('#zonaPianificazioneTable tbody tr');

            $(rows).each(function (index) {
                let key = ++index;
                $(this).attr('id', 'zonaPianificazioneRow_' + key);

                let inputs = $(this).find('input[type=text]');

                $(inputs).each(function () {
                    let name = $(this).attr('name').split('_');
                    let latestIndex = name.pop();
                    name.push(key);
                    $(this).attr('name', name.join('_'))
                });
            });
        }
    });


    function createNewArchivio(allReport) {
        let newReport = $('.archivio-block');
        newReport.children().remove();
        $.each(newReport, function (i) {
            // console.log(this);
            let parent = this;
            $(allReport).each(function (index) {
                let check = this.active === 1 ? 'checked' : '';
                let switchId = i > 0 ? 'switch2_' : 'switch_';
                let disabled = this.active !== 1 ? 'disabled' : '';
                $(parent).append('<div class="row"><div class="col-4 archivio-data">Data creazione : <span class="archivio-date">' + moment(this.date_document).format('DD/MM/YYYY') + '</span></div>' +
                    '<div class="col-4 archivio-check">' +
                    '<div class="custom-control custom-switch">' +
                    '<input type="checkbox" class="custom-control-input switch_checkbox" ' + check + ' data-name="' + this.name + '" data-id="' + this.id + '" id="' + switchId + this.id + '">' +
                    '<label class="custom-control-label" for="' + switchId + this.id + '"></label>' +
                    '</div>' +
                    '</div>' +
                    '<div class="col-4 archivio-file">' +
                    '<a href="/' + this.path_file.replace(/public/, 'storage') + '" data-imageId="' + this.id + '" target="_blank">' + this.name + '.pdf</a>' +
                    '</div>' +
                    '</div>');
            });
        });
    }

    function createNewPianificazione(allReport) {
        let newEmailTemplate = $('.client-block__pianificazione');
        newEmailTemplate.children().remove();

        $.each(newEmailTemplate, function (i) {
            let parent = this;
            $(allReport).each(function (index) {
                $(parent).append('<div class="pianificazione_scadenze">' +
                    '    <div class="d-flex justify-content-between">' +
                    '        <div><span class="td-section__title pr-5">Data</span> <span class="td-section__text">' + moment(this.date_document).format('DD/MM/YYYY') + '</span></div>' +
                    '        <div><i id="template_edit_' + this.email_template.id + '" class="far fa-edit pr-3 edit-scadenze-email-template"></i>' +
                    '            <i id="send_email_' + this.id + '" class="far fa-envelope send-scadenze-email" aria-hidden="true"></i></div>' +
                    '    </div>' +
                    '    <div class="tr-section" id="template_content_' + this.email_template.id + '">' + this.email_template.template +
                    '    </div>' +
                    '</div>');
            });
        });
    }

    function updateAggiungiVerbaleBlock(allReport) {
        let newReport = $('#aggiungi_verbale_body_block');
        newReport.children().remove();
        $.each(newReport, function (i) {
            // console.log(this);
            let parent = this;
            $(allReport).each(function (index) {
                $(parent).append('<tr class="row tr-section">' +
                    '    <td class="col-3 td-section text-break">' +
                    '        <div class="td-section__title">Data</div>' +
                    '        <div class="td-section__text">' + moment(this.date_document).format('DD/MM/YYYY') + '</div>' +
                    '    </td>' +
                    '    <td class="col-3 td-section text-break">' +
                    '        <div class="td-section__title">Tipo</div>' +
                    '        <div class="td-section__text">' + this.fase + '</div>' +
                    '    </td>' +
                    '    <td class="col-4 td-section td-section-prl-0">' +
                    '        <div class="td-section__title">Oggetto</div>' +
                    '        <div class="td-section__text">' + this.name + '</div>' +
                    '    </td>' +
                    '    <td class="col-2 td-section td-section__img td-section-prl-0">' +
                    '        <a href="/' + this.path_file.replace(/public/, 'storage') + '" data-imageId="' + this.id + '" target="_blank"><img src="/images/client/download-pic.png" alt=""></a>' +
                    '        <i class="ti-close remove_report_pdf" data-id="' + this.id + '" id="remove_report_id_' + this.id + '"></i>' +
                    '    </td>' +
                    '</tr>');
            });
        });
    }

    function clearAggiungiVerbaleModal() {
        $('#name_file')[0].value = '';
        $('#data_documento')[0].value = '';
        $('#note')[0].value = '';
        $('#importante')[0].value = '';
        $('#date_sopralluogo')[0].value = '';
        $('#time_sopralluogo')[0].value = '';
        $('#date_rossimo_incontro')[0].value = '';
        $('#time_rossimo_incontro')[0].value = '';
        $('#time_seleziona')[0].value = '';
    }

    function setLatestPhase(allReport) {
        if (allReport.length > 0) {
            $('#agguingi_varbale .seleziona-fase-text').text(allReport[0].fase);
        }
    }

    $('#crea_nuovo').on('hide.bs.modal', function (e) {

        setTimeout(function () {
            $('#modulo_screening').modal('show');
        }, 700);

    });

    $('.show-all-text').on('click', function () {
        let name = '#' + this.dataset.name;
        let description = $(name + ' .description-cer-content').text();
        if (!this.classList.contains('active')) {
            $(this).addClass('active');
            $(name).css('height', '100%');
            if (description.length > 248) {
                $(this).parent().parent().removeClass('mb-1');
                $(this).parent().parent().addClass('mb-3');
            }

            $(name + ' .description-cer-content').text(description.replace("...", ""));
        } else {
            $(this).removeClass('active');
            $(name).css('height', '31px');
            if (description.length > 248) {
                $(this).parent().parent().removeClass('mb-3');
                $(this).parent().parent().addClass('mb-1');
                let longDescription = $(name + ' .description-cer-content').text();
                $(name + ' .description-cer-content').html(longDescription.slice(0, 172) + "...</br>" + longDescription.slice(172));
            }
        }

    });

    function serchParentClass(classList) {
        let parentClass = 'popup1';
        for (let item in classList) {
            if (classList[item] === 'popup1' || classList[item] === 'popup2') {
                return classList[item];
            }
        }
        return parentClass
    }

    // add new element in agguingi varbale box
    $('.attivita_svolte').on('click', function () {

        let parentClass = '.' + serchParentClass($(this)[0].parentNode.classList);

        let items = $(parentClass + ' .agguingi_varbale-checkbox-row');
        let countNextItem = items[0].children.length + 1;

        countNextItem = serchClass('agguingi_varbale-item-' + countNextItem, countNextItem);

        $(parentClass + ' .attivita_svolte').css('display', 'none');

        items.append('<div class="row agguingi_varbale-item-' + countNextItem + '">');

        $(parentClass + ' .agguingi_varbale-item-' + countNextItem)
            .append('<div class="custom-control custom-checkbox mr-sm-2 col-8 custom-control-agguingi_varbale">')
            .append('<select class="col fase-block-text select-fase">')
            .append('<div class="col-auto"><i class="fas fa-check-circle btn-add-input">');

        $(parentClass + ' .agguingi_varbale-item-' + countNextItem + ' select')
            .append('<option value="Screening">Screening</option>')
            .append('<option value="Pianificazione">Pianificazione</option>')
            .append('<option value="Partenza">Partenza</option>')
            .append('<option value="Man/Agg">Man/Agg</option>');

        $(parentClass + ' .agguingi_varbale-item-' + countNextItem + ' .custom-checkbox')
            .append('<input type="checkbox" class="custom-control-input" id="customControlAutosizing' + countNextItem + '">')
            .append('<label class="custom-control-label" for="customControlAutosizing' + countNextItem + '">')
            .append('<input class="customControlInput" id="customControlInput' + countNextItem + '">');

        //Add btn click
        $(parentClass + ' .btn-add-input').on('click', function () {

            let checkboxValue = $(parentClass + ' #customControlAutosizing' + countNextItem)[0].checked;
            let inputText = $(parentClass + ' #customControlInput' + countNextItem)[0].value;
            let selectValue = $(parentClass + ' .agguingi_varbale-item-' + countNextItem + ' select')[0].value;

            $(parentClass + ' .agguingi_varbale-item-' + countNextItem).remove();

            if (inputText !== '') {
                if (checkboxValue) {

                    addAgguingiVarbale(inputText, selectValue);

                } else {

                    addCriticita(inputText, selectValue);
                }
            }
            $('.attivita_svolte').css('display', 'block');

        });
    });

    // add new element in criticita box
    $('.criticita-plus').on('click', function () {

        let parentClass = '.' + serchParentClass($(this)[0].parentNode.classList);
        let items = $(parentClass + ' .criticita-checkbox-row');
        let countNextItem = items[0].children.length + 1;

        countNextItem = serchClass('criticita-item-' + countNextItem, countNextItem);

        $('.criticita-plus').css('display', 'none');

        items.append('<div class="row criticita-item-' + countNextItem + '">');

        $(parentClass + ' .criticita-item-' + countNextItem)
            .append('<div class="custom-control custom-checkbox mr-sm-2 col-8 custom-control-agguingi_varbale">')
            .append('<select class="col fase-block-text select-fase">')
            .append('<div class="col-auto"><i class="fas fa-check-circle btn-add-input">');

        $(parentClass + ' .criticita-item-' + countNextItem + ' select')
            .append('<option value="Screening">Screening</option>')
            .append('<option value="Pianificazione">Pianificazione</option>')
            .append('<option value="Partenza">Partenza</option>')
            .append('<option value="Man/Agg">Man/Agg</option>');

        $(parentClass + ' .criticita-item-' + countNextItem + ' .custom-checkbox')
            .append('<input type="checkbox" class="custom-control-input" id="customControlCriticita' + countNextItem + '">')
            .append('<label class="custom-control-label" for="customControlCriticita' + countNextItem + '">')
            .append('<input class="customControlInput" id="customCriticitaInput' + countNextItem + '">');

        //Add btn click

        $(parentClass + ' .btn-add-input').on('click', function () {
            let checkboxValue = $(parentClass + ' #customControlCriticita' + countNextItem)[0].checked;
            let inputText = $(parentClass + ' #customCriticitaInput' + countNextItem)[0].value;
            let selectValue = $(parentClass + ' .criticita-item-' + countNextItem + ' select')[0].value;

            $(parentClass + ' .criticita-item-' + countNextItem).remove();

            if (inputText !== '') {
                if (checkboxValue) {
                    addAgguingiVarbale(inputText, selectValue);
                } else {

                    addCriticita(inputText, selectValue);
                }
            }
            $('.criticita-plus').css('display', 'block');

        });
    });

    // function add new element in agguingi varbale box
    function addAgguingiVarbale(inputText, selectValue = 'Screening') {

        let items = $('.agguingi_varbale-checkbox-row');
        let countNextItem = items[0].children.length + 1;

        countNextItem = serchClass('agguingi_varbale-item-' + countNextItem, countNextItem);

        items.append('<div class="row agguingi_varbale-item agguingi_varbale-item-' + countNextItem + '">');

        $('.agguingi_varbale-item-' + countNextItem)
            .append('<div class="custom-control custom-checkbox mr-sm-2 col-8 custom-control-agguingi_varbale">')
            .append('<div class="col fase-block-text">' + selectValue)
            .append('<div class="col-auto" style="margin-left: -25px;"><i class="far fa-edit pr-1 edit-item" data-edit="agguingi_varbale-item-' + countNextItem + '"></i><i class="ti-close remove-item" data-remove="agguingi_varbale-item-' + countNextItem + '"/>');

        $('.agguingi_varbale-item-' + countNextItem + ' .custom-checkbox')
            .append('<input type="checkbox" data-remove="agguingi_varbale-item-' + countNextItem + '" class="custom-control-input" checked id="customControlAutosizing' + countNextItem + '">')
            .append('<label class="custom-control-label" for="customControlAutosizing' + countNextItem + '">' + inputText);
    }

    // function add new element in criticita box
    function addCriticita(inputText, selectValue = 'Screening') {

        let items = $('.criticita-checkbox-row');
        let countNextItem = items[0].children.length + 1;

        countNextItem = serchClass('criticita-item-' + countNextItem, countNextItem);

        items.append('<div class="row agguingi_varbale-item criticita-item-' + countNextItem + '">');

        $('.criticita-item-' + countNextItem)
            .append('<div class="custom-control custom-checkbox mr-sm-2 col-8 custom-control-agguingi_varbale">')
            .append('<div class="col fase-block-text">' + selectValue)
            .append('<div class="col-auto" style="margin-left: -25px;"><i class="far fa-edit pr-1 edit-item" data-edit="criticita-item-' + countNextItem + '"></i><i class="ti-close remove-item" data-remove="criticita-item-' + countNextItem + '">');

        $('.criticita-item-' + countNextItem + ' .custom-checkbox')
            .append('<input type="checkbox" data-remove="criticita-item-' + countNextItem + '" class="custom-control-input" id="customControlCriticita' + countNextItem + '">')
            .append('<label class="custom-control-label" for="customControlCriticita' + countNextItem + '">' + inputText);
    }

    // Search and class changes to avoid duplication of id and classes
    function serchClass(nameClass, count) {

        let newNameClass = nameClass;
        let newPrefix = count;

        while (true) {
            if ($('.agguingi_varbale-item').hasClass(newNameClass)) {
                newNameClass = nameClass + '-' + newPrefix;
                newPrefix += '-' + count;
            } else {
                return newPrefix;
            }
        }
    }

    $('.seleziona-fase').on('change', function (e) {
        let elment = $(this)[0];
        let change = $('[data-id="' + elment.dataset.id + '"]');

        let data = {
            id: elment.dataset.id,
            val: change.val()
        };
        $('#loader').removeClass('fadeOut');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/changeAnalisiCer',
            data: data,
            type: 'post',
            success: function () {
                $('#loader').addClass('fadeOut');
            },
            error: function (e) {
                $('#loader').addClass('fadeOut');
                if (e) {
                    setTimeout(function () {
                        alert(e);
                    }, 700);
                }
            }
        });
    });
    $(document).on('change', '.switch_checkbox', function (e) {
        let elment = $(this)[0];
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $('#loader').removeClass('fadeOut');
        $.ajax({
            url: '/changeActivePdf',
            data: $(this)[0].dataset,
            type: 'post',
            success: function () {
                $('[data-id="' + elment.dataset.id + '"]').each(function (index) {
                    $(this)[0].checked = elment.checked;
                    // if (!elment.checked) {
                    //     $('[data-imageId=' + this.dataset.id + ']').addClass('disabled');
                    // } else {
                    //     $('[data-imageId=' + this.dataset.id + ']').removeClass('disabled');
                    // }
                });
                $('#loader').addClass('fadeOut');
            },
            error: function (e) {
                $('#loader').addClass('fadeOut');
                if (e) {
                    setTimeout(function () {
                        alert(e);
                    }, 700);
                }
            }
        });
    });

    //remove btn
    $(document).on('click', '.remove-item', function () {
        let remove = '.' + $(this)[0].dataset.remove;
        $(remove).remove();
    });

    $(document).on('click', '.edit-item', function () {
        let editableBlock = '.' + $(this)[0].dataset.edit + '';

        let contentEditableDiv = $(editableBlock + ' .custom-control-label');
        let checkbox = $(editableBlock + ' .custom-control-input');

        let isEditable = contentEditableDiv.is('.editable');

        if (contentEditableDiv.is('.editable')) {
            $(this).removeClass('fas fa-check').addClass('far fa-edit');
        } else {
            $(this).removeClass('far fa-edit').addClass('fas fa-check');
        }

        $(checkbox).prop('disabled', !isEditable);
        contentEditableDiv.prop('contenteditable', !isEditable).toggleClass('editable')
    });

    //changing the operation of the checkbox and transferring the chuckbox to the desired box
    $(document).on('change', '.custom-control-input', function () {
        if ($(this).siblings('.editable').length) {
            return true;
        }

        let remove = '.' + $(this)[0].dataset.remove;
        if ($(remove)[0]) {
            let inputText = $(remove)[0].children[0].textContent;
            let selectValue = $(remove)[0].children[1].textContent;

            if (this.checked) {
                $(remove).remove();
                addAgguingiVarbale(inputText, selectValue);
            } else {
                $(remove).remove();
                addCriticita(inputText, selectValue);
            }
        }

    });

    $('.prescrizioni').on('click', function (e) {
        if (!$('.input_prescrizioni').length) {

            let prescrizioniText = $('.prescrizioni-text' + $(this)[0].dataset.cerId)[0];
            let oldText = prescrizioniText.textContent;

            prescrizioniText.textContent = '';

            $(this).append('<textarea type="text" data-id-prescrizioni="' + $(this)[0].dataset.cerId + '" class="input_prescrizioni">' + oldText + '</textarea>');
            $('.input_prescrizioni').focus();
        }
    });

    $(document).on('change', '.input_prescrizioni', function () {

        let data = {
            id: $(this)[0].dataset.idPrescrizioni,
            text: $(this)[0].value
        }
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $('#loader').removeClass('fadeOut');
        $.ajax({
            type: "POST",
            url: "/change-prescrizioni",
            data: data,
            success: function (res) {
                $('#loader').addClass('fadeOut');
            }
        });

        $('.prescrizioni-text' + $(this)[0].dataset.idPrescrizioni)[0].textContent = $(this)[0].value;
        this.remove();
    });

    $('#btn-avanzamento').on('click', function () {
        let client = (document.location.pathname);
        let clientId = client.split('/')[2];
        let agguingi_varbale = $('.popup1 .agguingi_varbale-checkbox-row .agguingi_varbale-item');

        let agguingi_varbale_arr = [];
        if (agguingi_varbale.length > 0) {
            for (let i = 0; i < agguingi_varbale.length; i++) {
                agguingi_varbale_arr.push(
                    {
                        'text': agguingi_varbale[i].children[0].innerText,
                        'phase': agguingi_varbale[i].children[1].innerText,
                    }
                );
            }
        }
        let criticita = $('.popup1 .criticita-checkbox-row .agguingi_varbale-item');

        let criticita_arr = [];
        if (criticita.length > 0) {
            for (let i = 0; i < criticita.length; i++) {
                criticita_arr.push(
                    {
                        text: criticita[i].children[0].innerText,
                        phase: criticita[i].children[1].innerText,
                    }
                );
            }
        }
        // let agguingi = $('.popup1 .agguingi_varbale-checkbox-row')[0].children;
        // let criticita = $('.popup1 .criticita-checkbox-row')[0].children;
        // saveChackBoxValueInLocal(agguingi, criticita);

        let data = {
            agguingi_varbale: JSON.stringify(agguingi_varbale_arr),
            criticita: JSON.stringify(criticita_arr),
            id: clientId
        };

        $('#loader').removeClass('fadeOut');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/clienti/syncAttivitaCriticita',
            data: data,
            type: 'post',
            success: function () {
                $('#loader').addClass('fadeOut');
            },
            error: function (e) {
                $('#loader').addClass('fadeOut');
                if (e) {
                    setTimeout(function () {
                        alert(e);
                    }, 700);
                }
            }
        });
    });

    // $('#aggiungi_utente_annulla_btn').on('click', function () {
    //
    //     let agguingi = $('.popup2 .agguingi_varbale-checkbox-row')[0].children;
    //     let criticita = $('.popup2 .criticita-checkbox-row')[0].children;
    //
    //     saveChackBoxValueInLocal(agguingi, criticita);
    //
    // });

    function saveChackBoxValueInLocal(agguingi, criticita) {
        localStorage.clear('agguingi');
        localStorage.clear('criticita');

        // div.innerHTML = localStorage.getItem('obj');

        if (criticita.length > 0) {

            let arrCriticita = [];
            for (let child in criticita) {
                if (criticita[child].children) {

                    let check = criticita[child].children[0].children[0].checked;
                    let label = criticita[child].children[0].children[1].innerHTML;
                    let fase = criticita[child].children[1].innerHTML;

                    arrCriticita[child] = [check, label, fase];
                }
            }
            localStorage.setItem('criticita', JSON.stringify(arrCriticita));
            // console.log(arrCriticita);
            $(criticita).remove();
        }

        if (agguingi.length > 0) {

            let arrAgguingia = [];
            for (let child in agguingi) {
                if (agguingi[child].children) {
                    let check = agguingi[child].children[0].children[0].checked;
                    let label = agguingi[child].children[0].children[1].innerHTML;
                    let fase = agguingi[child].children[1].innerHTML;

                    arrAgguingia[child] = [check, label, fase];
                }
            }
            localStorage.setItem('agguingi', JSON.stringify(arrAgguingia));

            $(agguingi).remove();
        }
    }

    //modulo_di_aggiornamento_popup

    $(".modulo_di_aggiornamento").on('click', function () {

        $('#agguingi_varbale').modal("hide");

        setTimeout(function () {
            $('#modulo_di_aggiornamento').modal('show');
        }, 600);

    });

    $('#annulla_modulo_di_aggiornamento').on('click', function (e) {

        // setTimeout(function () {
        //     $('#agguingi_varbale').modal('show');
        // }, 700);
    });


    /////////////////////////////////aggiungi-rifiuto-two-step

    $(document).on('shown.bs.modal', '#aggiungi_rifiuto_two_step', function (event) {
        let codeCer = $('#select2-code-cer-container')[0].textContent;
        let codeCerLabel = codeCer.substr(9);
        $('#code-cer-description')[0].value = $.trim(codeCerLabel);
    });

    $(document).on('change', '.code-cer-description', function (event) {
        let codeCer = $('#select2-code-cer-container')[0].textContent;
        let codeCerLabel = codeCer.substr(9);
        $('#code-cer-description')[0].value = $.trim(codeCerLabel);

        if (codeCer.indexOf('*', 8) !== -1) {
            let indication_items = $('#indication-container .indication-item');
            let flag = true;
            // console.log(indication_items);
            if (indication_items.length > 0) {
                indication_items.each(function (index) {
                    if ($(this).children()[2]) {
                        if ($(this).children()[2].value === 'labels/clp/letera_r.jpg') {
                            flag = false;
                            //console.log(flag);
                        }
                    }
                });
            }
            if (flag) {
                $('#indication-container').append('<div class="text-center indication-item">' +
                    '    <div class="overflow-hidden">' +
                    '                    <img width="100%" src=" /storage/labels/clp/letera_r.jpg "><br>' +
                    '                <span class="indication-title">  </span>' +
                    '    </div>' +
                    '    <a href="javascript:void(0);" class="indication-item-delete"><div class="files-delete-border"><i class="ti-close"></i></div></a>' +
                    '            <input name="indication[' + (indication_items.length + 100) + '][img]" type="hidden" value="labels/clp/letera_r.jpg">' +
                    '        <input name="indication[' + (indication_items.length + 100) + '][text]" type="hidden" value="">' +
                    '</div>');
            }
        }
    });

    ///delete pdf
    $(document).on('click', '.remove_report_pdf', function (e) {
        let _this = $(this);
        e.preventDefault();
        Swal.fire({
            title: 'Sei sicuro?', // Opération Dangereuse
            text: 'Sei sicuro di continuare ?', // Êtes-vous sûr de continuer ?
            type: 'error',
            icon: 'error',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: 'null',
            cancelButtonColor: 'null',
            confirmButtonClass: 'remove-report-pdf text-center m-2',
            cancelButtonClass: 'btn-modal-cancel text-center m-2',
            confirmButtonText: 'Si certo!', // Oui, sûr
            cancelButtonText: 'Annulla', // Annuler
        }).then(res => {
            if (res.value) {
                _this.closest("form");
                let client = (document.location.pathname);
                let clientId = client.split('/')[2];
                let data = {
                    id: _this[0].dataset.id,
                    clientId: clientId
                };
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax({
                    url: '/destroyReport',
                    data: data,
                    type: 'post',
                    success: function (e) {
                        $('#loader').addClass('fadeOut');
                        if (e.allReport) {
                            createNewArchivio(e.allReport);
                            //updateAggiungiVerbaleBlock(e.allReport);
                            alert(e.success);
                            location.reload();
                        }
                    },
                    error: function (e) {
                        $('#loader').addClass('fadeOut');
                        if (e) {
                            alert(e);
                        }
                    }
                });
            }
        });
    });

    /////////////////////////modulo di aggiornamento
    $('.aggiungi_btn_nota').on('click', function (e) {

        let notaBlock = $('.nota-block_' + $(this)[0].dataset.idCer);
        if (notaBlock.hasClass('nota-block-active')) {
            notaBlock.removeClass('nota-block-active');
        } else {
            notaBlock.addClass('nota-block-active');
            notaBlock[0].children[0].focus();
        }
    });

    // $('.giacenza-ad-oggi').on('click', function () {
    //     if (!$('#giacenza-ad-oggi-input' + this.dataset.idGiacenza + '').length) {
    //         $(this).append('<input class="giacenza-ad-oggi-input" id="giacenza-ad-oggi-input' + this.dataset.idGiacenza + '">');
    //         $('#giacenza-ad-oggi-input' + this.dataset.idGiacenza).focus();
    //     }
    // });
    // $('.kg-prodotti-oggi').on('click', function () {
    //     if (!$('#kg-prodotti-oggi-input' + this.dataset.idProdotti + '').length) {
    //         $(this).append('<input class="kg-prodotti-oggi-input" id="kg-prodotti-oggi-input' + this.dataset.idProdotti + '">');
    //         $('#kg-prodotti-oggi-input' + this.dataset.idProdotti).focus();
    //     }
    // });
    // $('.n-colli-block').on('click', function () {
    //     if (!$('#n-colli-block-input' + this.dataset.idColli + '').length) {
    //         $(this).append('<input class="n-colli-block-input" id="n-colli-block-input' + this.dataset.idColli + '">');
    //         $('#n-colli-block-input' + this.dataset.idColli).focus();
    //     }
    // });

    $('#salva_modulo_di_aggiornamento').on('click', function () {

        $('#modulo_di_aggiornamento').modal("hide");

        setTimeout(function () {
            $('#modulo_di_aggiornamento_salva').modal('show');
        }, 600);
    });
    $('#di_aggiornamento_bozza').on('click', function () {

        $('#modulo_di_aggiornamento_salva').modal("hide");

        setTimeout(function () {
            $('#modulo_di_aggiornamento').modal('show');
        }, 600);
    });
    $('#di_aggiornamento_completa').on('click', function () {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $('#loader').removeClass('fadeOut');

        // let client = (document.location.pathname);
        // let clientId = client.split('/')[2];

        let clientId = this.dataset.id;


        let modulo_di_aggiornamento = $('.modulo_di_aggiornamento_block');

        let modulo_di_aggiornamento_arr = [];
        if (modulo_di_aggiornamento.length > 0) {
            for (let i = 0; i < modulo_di_aggiornamento.length; i++) {
                let id = modulo_di_aggiornamento[i].dataset.archivioBlockId;
                let giacenza_input = $('#giacenza-ad-oggi-input' + id).val();
                let prodotti_input = $('#kg-prodotti-oggi-input' + id).val();
                let colli_input = $('#n-colli-block-input' + id).val();
                let colli_giacenza_input = $('#n-colli-giacenza-block-input' + id).val();
                let cer_textarea = $('.cer_textarea_' + id).val();
                modulo_di_aggiornamento_arr.push({
                    'id': id,
                    'giacenza_input': giacenza_input,
                    'prodotti_input': prodotti_input,
                    'colli_input': colli_input,
                    'colli_giacenza_input': colli_giacenza_input,
                    'cer_textarea': cer_textarea
                });
            }
        }

        let data = {
            arr: JSON.stringify(modulo_di_aggiornamento_arr),
        };

        let url = '/aggiornamentoPdf/' + clientId;

        $.ajax({
            url: url,
            data: data,
            type: 'get',
            success: function (e) {
                if (e.success) {
                    $('#loader').addClass('fadeOut');
                    $('#modulo_di_aggiornamento_salva').modal('hide');
                    if (e.allReport) {
                        createNewArchivio(e.allReport);
                        updateAggiungiVerbaleBlock(e.allReport);
                    }
                    setTimeout(function () {
                        alert(e.success);
                    }, 1000);
                } else {
                    alert(e.errors);
                }
            },
            error: function (e) {
                $('#loader').addClass('fadeOut');
                if (e) {
                    alert(e.responseJSON.errors);
                }
            }
        });
    });

//pannelli adr PDF

    // $('.pannelli-adr-click').on('click', function () {
    //     $('#loader').removeClass('fadeOut');
    //     let client = (document.location.pathname);
    //     let clientId = client.split('/')[2];
    //     let data = {
    //         clientId:clientId
    //     };
    //     $.ajaxSetup({
    //         headers: {
    //             'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    //         }
    //     });
    //     let redirect = window.open('about:blank', '_blank');
    //     $(redirect.document.body).html("<h2 style='color:#525659;'>Attendi l'aggiornamento del documento...</h2>");
    //     $.ajax({
    //         url: '/createPannelliAdrReport',
    //         data: data,
    //         type: 'post',
    //         success: function (e) {
    //             $('#loader').addClass('fadeOut');
    //             if (e.clientCertificateRaport) {
    //                 refrashDate(e.clientCertificateRaport);
    //                 redirect.location.href = '/' + e.clientCertificateRaport.path_file.replace(/public/, 'storage');
    //             }
    //         },
    //         error: function (e) {
    //             $('#loader').addClass('fadeOut');
    //             if (e) {
    //                 alert(e);
    //             }
    //         }
    //     });
    // });

    function refrashDate(clientCertificateRaport) {
        let modifica = $('.data-ultima-modifica')[0];
        let stampa = $('.data-ultima-stampa')[0];
        modifica.textContent = moment(clientCertificateRaport.last_modification_date).format('DD-MM-YYYY');
        stampa.textContent = moment(clientCertificateRaport.last_printed_date).format('DD-MM-YYYY');
    }

    $('.add-fatturato-incrementale').on('click', function () {

        let parent = $('#fatturato_panels_block');
        let len = parent.length;
        let id = this.dataset.id;

        parent.append('<div class="row p-0 mb-3" id="block_' + len + '">' +
            '             <div class="col-md-3 fatturato_panels_item padding-r0"><input type="number" name="anno" data-name="anno" class="fatturato_panels_input"></div>' +
            '             <div class="col-md-4 fatturato_panels_item padding-r0"><input type="number" name="fatturato" data-name="fatturato" class="fatturato_panels_input"> <span>€</span></div>' +
            '             <div class="col-md-4 fatturato_panels_item padding-r0"><input type="number" name="utile" data-name="utile" class="fatturato_panels_input"> <span>€</span></div>' +
            '             <div class="btn-add-input-fatturato new-append-block-' + len + '"><i class="fas fa-check-circle add-input-fatturato" data-id="' + id + '" data-block-id="' + len + '" data-block="block_' + len + '"></i></div>' +
            '         </div>');

        $('.add-fatturato-incrementale').css('display', 'none');
        $('.nessun-dato-label').remove();

    });

    $(document).on('change', '.fatturato_panels_input', function (e) {
        let block = $('.num_block_' + this.dataset.id);
        let id = this.dataset.id;
        let temp = $('.num_block_' + this.dataset.id + ' .btn-add-input-fatturato');
        if (temp.length === 0) {
            block.append('<div class="btn-add-input-fatturato append-block-' + id + '">' +
                '<i class="fas fa-check-circle edit-input-fatturato" data-id="' + id + '" data-block="num_block_' + this.dataset.id + '"></i>' +
                '</div>');
        }
    });

    $(document).on('click', '.add-input-fatturato', function (e) {

        let url = '/createFatturatoIncrementale';
        let nameBlock = this.dataset.block;
        let clientID = this.dataset.id;
        let id = this.dataset.blockId;
        let newBlock = $('#' + nameBlock + ' input');

        let data = {
            'clientID': clientID
        };
        newBlock.each(function (index) {
            data[this.name] = this.value;
        });

        sendAjaxFatturatoIncrementale(url, data);

        $('.add-fatturato-incrementale').css('display', 'block');
        $('.new-append-block-' + id).remove();

    });

    $(document).on('click', '.edit-input-fatturato', function (e) {

        let url = '/editFatturatoIncrementale';
        let nameBlock = this.dataset.block;
        let id = this.dataset.id;
        let newBlock = $('.' + nameBlock + ' input');

        let data = {
            'id': id
        };
        newBlock.each(function (index) {
            data[this.name] = this.value;
        });

        sendAjaxFatturatoIncrementale(url, data);

        $('.add-fatturato-incrementale').css('display', 'block');
        $('.append-block-' + id).remove();

    });

    function sendAjaxFatturatoIncrementale(url, data) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $('#loader').removeClass('fadeOut');
        $.ajax({
            url: url,
            data: data,
            type: 'post',
            success: function (e) {
                if (e) {
                    setTimeout(function () {
                        alert('Modifiche aggiunte');
                    }, 700);
                    $('#loader').addClass('fadeOut');
                }
            },
            error: function (e) {
                $('#loader').addClass('fadeOut');
                if (e) {
                    setTimeout(function () {
                        alert('Errore qualcosa è andato storto');
                    }, 700);
                }
            }
        });
    }

    $(document).on('click', '.edit-scadenze-email-template', function (e) {
        let arrayPath = e.target.id.split('_');
        let templateId = arrayPath[arrayPath.length - 1];
        let contentEditableDiv = $('#template_content_' + templateId);
        let isEditable = contentEditableDiv.is('.editable');

        if (contentEditableDiv.is('.editable')) {
            let data = {
                id: templateId,
                template: contentEditableDiv.text()
            };
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                url: '/updateEmailTemplate',
                data: data,
                type: 'patch',
                success: function (e) {
                    $('#loader').addClass('fadeOut');
                    if (e.template) {
                        alert(e.success);
                        contentEditableDiv.text(e.template);
                    }
                },
                error: function (e) {
                    $('#loader').addClass('fadeOut');
                    if (e) {
                        alert(e);
                    }
                }
            });
            $(this).removeClass('fas fa-check').addClass('far fa-edit');
        } else {
            $(this).removeClass('far fa-edit').addClass('fas fa-check');
        }

        contentEditableDiv.prop('contenteditable', !isEditable).toggleClass('editable')
    });

    // Notify client about report expiration
    $(document).on('click', '.send-scadenze-email', function (e) {
        let _this = $(this);
        e.preventDefault();
        Swal.fire({
            title: 'Sei sicuro?', // Opération Dangereuse
            text: 'Sei sicuro di continuare ?', // Êtes-vous sûr de continuer ?
            type: 'warning',
            icon: 'warning',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: 'null',
            cancelButtonColor: 'null',
            confirmButtonClass: 'remove-report-pdf text-center m-2',
            cancelButtonClass: 'btn-modal-cancel text-center m-2',
            confirmButtonText: 'Si certo!', // Oui, sûr
            cancelButtonText: 'Annulla', // Annuler
        }).then(res => {
            if (res.value) {
                let clientReportId = e.target.id.split('_').pop();
                let data = {
                    id: clientReportId
                };
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax({
                    url: '/notifyClientAboutReportExpiration',
                    data: data,
                    type: 'post',
                    success: function (e) {
                        $('#loader').addClass('fadeOut');
                        if (e) {
                            alert(e.success);
                        }
                    },
                    error: function (e) {
                        $('#loader').addClass('fadeOut');
                        if (e) {
                            alert(e);
                        }
                    }
                });
            }
        });
    });


    //brand-upload
    $('#brand-logo-upload').on('click', function () {
        document.getElementById('file-logo-upload').click();
    });

    $('#brand-rifiuti-upload').on('click', function () {
        document.getElementById('file-rifiuti-upload').click();
    });

    $('#file-logo-upload').on('change', function () {
        if (this.files && this.files[0]) {
            brandUploadFile(this.files[0], 'logo')
        }
    });

    $('#file-rifiuti-upload').on('change', function () {
        if (this.files && this.files[0]) {
            brandUploadFile(this.files[0], 'rifiuti')
        }
    });

    function brandUploadFile(file, alias) {
        let data = new FormData();
        data.append('file', file);
        data.append('alias', alias);
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/brand-upload-file',
            data: data,
            type: 'post',
            processData: false,
            contentType: false,
            success: function (e) {
                if (e) {
                    window.location.href = '/brand';
                }
            },
            error: function (e) {
                alert('Errore qualcosa è andato storto');
            }
        });
    }

    $('#brand-logo-remove').on('click', function () {
        brandRemoveFile('logo');
    });

    $('#brand-rifiuti-remove').on('click', function () {
        brandRemoveFile('rifiuti');
    });

    function brandRemoveFile(alias) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/brand-remove-file',
            data: {alias: alias},
            type: 'post',

            success: function (e) {
                if (e) {
                    window.location.href = '/brand';
                }
            },
            error: function (e) {
                alert('Errore qualcosa è andato storto');
            }
        });
    }

    ///provvisorio-checkbox

    $('#provvisorio-checkbox').on('change', function () {

        let params = {
            id: this.dataset.id,
            check: this.checked === true ? 1 : 0
        }

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/clienti/provvisorioChecked',
            data: params,
            type: 'post',

            success: function (e) {
                if (e) {
                }
            },
            error: function (e) {
                alert('Errore qualcosa è andato storto');
            }
        });

    });

    $('#esporta-tabella-screening-xls').on('click', function () {
        document.querySelectorAll('.blobXlsx').forEach(e => e.remove());
        let params = {
            id: this.dataset.id,
        }
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/clienti/generateXlsCerClient',
            data: params,
            type: 'post',

            success: function (e) {
                if (e) {
                    //console.log(e);
                    const path = e.split('public');
                    const url = '/storage' + path.pop();
                    const link = document.createElement('a');
                    link.href = url;
                    link.className = 'blobXlsx';
                    link.setAttribute('download', path.pop().split('/').pop());
                    document.body.appendChild(link);
                    link.click();
                }
            },
            error: function (e) {
                alert(e);
            }
        });
    });

    $('#esporta-tabella-adr-xls').on('click', function () {
        document.querySelectorAll('.blobXlsx').forEach(e => e.remove());
        let params = {
            id: this.dataset.id,
        }
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/clienti/generateXlsAdrTableCerClient',
            data: params,
            type: 'post',
            success: function (e) {
                if (e) {
                    //console.log(e);
                    const path = e.split('public');
                    const url = '/storage' + path.pop();
                    const link = document.createElement('a');
                    link.href = url;
                    link.className = 'blobXlsx';
                    link.setAttribute('download', path.pop().split('/').pop());
                    document.body.appendChild(link);
                    link.click();
                }
            },
            error: function (e) {
                alert(e);
            }
        });
    });

    $("#clickDocumenti").click(function() { // ID откуда кливаем
        $('html, body').animate({
            scrollTop: $("#scrollTopDocumenti").offset().top  // класс объекта к которому приезжаем
        }, 1000); // Скорость прокрутки
    });

});

