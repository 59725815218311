<template>
    <div class="cloud-modal-component">
        <b-modal :id="nameModal" hide-footer hide-header-close @show="init" @hide="reset">
            <template #modal-title>
                <div class="title-cloud-modal">
                    {{name}}
                </div>
            </template>
            <div class="d-block text-center position-relative cloud-modal-block" v-if="name !== 'Note'">
                <div class="cloud-modal-header-items-block">
                    <div>DOCUMENTO</div>
                    <div>LINK</div>
                    <div></div>
                </div>

                <div v-if="loader" class="cloud-modal-loader">
                    <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>

                <div class="cloud-modal-items-block" v-for="item in items">
                    <div>{{item.document_name}}</div>
                    <div>
                        <a target="_blank" :href="item.link">{{item.link}}</a>
                    </div>
                    <div class="cloud-modal-items-btn-block" style="margin-right: 3px">
                        <b-iconstack @click="edit(item)" v-if="flag === 1">
                            <b-icon stacked icon="pencil-fill" scale="0.5"></b-icon>
                            <b-icon stacked icon="circle" style="color: #B3B4B5"></b-icon>
                        </b-iconstack>
                        <b-iconstack @click="remove(item)" v-if="flag === 1">
                            <b-icon stacked icon="x" scale="0.8" style="color: red"></b-icon>
                            <b-icon stacked icon="circle" style="color: #B3B4B5"></b-icon>
                        </b-iconstack>

                    </div>
                </div>
            </div>

            <div class="d-block text-center position-relative cloud-modal-block" v-else>
                <div class="cloud-modal-header-items-block-note">
                    <div>NOTE</div>
                    <div></div>
                    <div></div>
                </div>

                <div v-if="loader" class="cloud-modal-loader">
                    <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>

                <div class="cloud-modal-items-block-note" v-for="item in items">
                    <div>
                        <pre style="white-space: pre-wrap;">{{item.document_name}}</pre>
                    </div>
                    <!--                    <div>-->
                    <!--                        <a target="_blank" :href="item.link">{{item.link}}</a>-->
                    <!--                    </div>-->
                    <div class="cloud-modal-items-btn-block" style="margin-right: 3px">
                        <b-iconstack @click="edit(item)" v-if="flag === 1">
                            <b-icon stacked icon="pencil-fill" scale="0.5"></b-icon>
                            <b-icon stacked icon="circle" style="color: #B3B4B5"></b-icon>
                        </b-iconstack>
                        <b-iconstack @click="remove(item)" v-if="flag === 1">
                            <b-icon stacked icon="x" scale="0.8" style="color: red"></b-icon>
                            <b-icon stacked icon="circle" style="color: #B3B4B5"></b-icon>
                        </b-iconstack>

                    </div>
                </div>
            </div>
            <div class="flex align-items-center justify-content-end mt-4">
                <div class="cloud-modal-add-new" @click="add" v-if="flag === 1">
                    Aggiungi
                </div>
            </div>
            <div class="btn-cloud-modal-component flex align-items-center justify-content-center">
                <b-button class="btn-modal-cancel text-center border-0 annulla_documents_upload"
                          @click="$bvModal.hide(nameModal)">
                    Annulla
                </b-button>
                <b-button class="btn-modal-cancel text-center border-0 annulla_documents_upload"
                          style="background-color: #29B55A; margin-left: 50px"
                          @click="$bvModal.hide(nameModal)">
                    Conferma
                </b-button>
            </div>
        </b-modal>


        <!--second modal-->
        <add-cloud-modal-component
            :parent-id="parentId"
            :url-pfefix="urlPfefix"
            :name="name"
            :item="item"
            :name-modal="nameModal+'-add'"
            :returnMethod="init"
        ></add-cloud-modal-component>

        <!--delete modal-->
        <delete-cloud-modal-component
            :parent-id="parentId"
            :url-pfefix="urlPfefix"
            :name="name"
            :item="item"
            :name-modal="nameModal+'-delete'"
            :returnMethod="init"
        ></delete-cloud-modal-component>
    </div>
</template>

<script>
    import AddCloudModalComponent from "./AddCloudModalComponent";
    import DeleteCloudModalComponent from "./DeleteCloudModalComponent";

    export default {
        name: "CloudModalComponent",
        props: ['parentId', 'name', 'nameModal', 'data', 'urlPfefix', 'flag'],
        data() {
            return {
                loader: false,
                items: [],
                item: {}
            }
        },
        components: {
            AddCloudModalComponent,
            DeleteCloudModalComponent
        },
        methods: {
            edit(item) {
                this.item = {...item};
                setTimeout(() => {
                    this.$bvModal.show(this.nameModal + '-add');
                }, 200);
            },

            remove(item) {
                this.item = {...item};
                setTimeout(() => {
                    this.$bvModal.show(this.nameModal + '-delete');
                }, 200);
            },

            add() {
                this.item = {};
                setTimeout(() => {
                    this.$bvModal.show(this.nameModal + '-add');
                }, 200);
            },

            reset() {
                this.items = [];
            },

            init() {
                this.item = {};
                this.loader = true;
                let params = {
                    id: this.parentId,
                    category: this.name,
                };
                axios.post('/' + this.urlPfefix + '/cloudDocuments', params).then(res => {
                    if (res.data) {
                        this.items = _.orderBy(res.data, 'created_at', 'desc');
                    }
                    this.loader = false;
                }).catch(error => {
                    this.loader = false;
                    alert(error);
                });
            }
        },
        mounted() {
            //console.log('flag', this.flag)
        }
    }

</script>

<style>
    .modal-dialog {
        max-width: 800px;
    }

    .modal-content {
        max-width: 800px;
        background-color: #fff;
        border-radius: 40px;
        padding: 40px;
    }

    .cloud-modal-block {
        min-height: 70px;
        max-height: 60vh;
        overflow-y: auto;
    }

    .cloud-modal-block::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
    }

    .cloud-modal-block::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: rgb(41, 181, 90);
    }

    .modal-header {
        border-bottom: 2px solid #000;
        padding: 0;
    }

    .modal-title {
        width: 100%;
    }

    .modal-body {
        padding: 0;
    }

    .title-cloud-modal {
        text-align: center;
        font-size: 35px;
    }

    .btn-cloud-modal-component button {
        width: 156px;
        border-radius: 20.5px;
        background-color: #9B9B9B;
        display: inline-block;
        color: #fff;
        cursor: pointer;
        font-size: 24px;
        font-weight: 500;
    }

    .cloud-modal-header-items-block, .cloud-modal-header-items-block-note {
        display: flex;
        align-items: center;
        justify-content: left;
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        margin: 10px 0 20px;
    }

    .cloud-modal-header-items-block div:nth-child(1),
    .cloud-modal-items-block div:nth-child(1) {
        width: 30%;
        padding-right: 10px;
    }

    .cloud-modal-header-items-block-note div:nth-child(1),
    .cloud-modal-items-block-note div:nth-child(1) {
        width: 86%;
        padding-right: 10px;
    }

    .cloud-modal-header-items-block div:nth-child(2),
    .cloud-modal-items-block div:nth-child(2) {
        width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    /*.cloud-modal-header-items-block-note div:nth-child(2),*/
    /*.cloud-modal-items-block-note div:nth-child(2) {*/
    /*    width: 50%;*/
    /*    overflow: hidden;*/
    /*    text-overflow: ellipsis;*/
    /*}*/

    .cloud-modal-items-block, .cloud-modal-items-block-note {
        display: flex;
        align-items: flex-start;
        justify-content: left;
        text-align: left;
        font-size: 17px;
        font-weight: 400;
        padding: 8px 0;
        border-bottom: 1px solid #B3B4B5;
        margin-bottom: 5px;
    }

    .cloud-modal-items-btn-block {
        display: flex;
        font-size: 35px;
        flex-direction: row;
        width: 20%;
        justify-content: flex-end;
    }

    .cloud-modal-items-btn-block svg {
        margin-left: 8px;
        cursor: pointer;
    }

    .cloud-modal-items-block a, .cloud-modal-items-block-note a {
        color: #007bff;
    }

    .cloud-modal-add-new {
        font-size: 19px;
        font-weight: 400;
        padding: 3px 8px;
        border: 1px solid #B3B4B5;
        border-radius: 20.5px;
        cursor: pointer;
    }

    .cloud-modal-loader {
        position: absolute;
        top: 36%;
        left: 50%;
    }
</style>
