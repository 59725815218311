import * as $ from 'jquery';
import 'bootstrap';

$(document).ready(function () {
    // when 'are you sure' modal opens get user id
    $(document).on('click', '.remove_button', function () {

        if ($(this).hasClass('newly_created')) {
            $('#rimuovere_utente').modal('show');
            $('#succes_add').hide();
            $('#succes_update').hide();
            $('#succes_delete').hide();
            $('#error_untete_delete').hide();
        }

        var _user_to_be_removed_id = $(this).attr('id').replace('utente_', '');
        $('#rimuovere_utente_btn').data('userid', _user_to_be_removed_id);

    });

    $(document).on('shown.bs.modal', '#agguingi_utente', function (e) {
        changeAddTipoUtente();
    });

    $(document).on('shown.bs.modal', '#edit_utente', function (e) {
        changeEditTipoUtente();
    });

    // on close 'are you sure' popup
    $(document).on('click', '#rimuovere_anulla_btn', function () {

        $('#rimuovere_utente').modal('hide');
        $('#rimuovere_utente_btn').data('userid', '');

    });

    $(document).on('click', '#rimuovere_utente_btn', function () {

        var user_id = $('#rimuovere_utente_btn').data('userid');

        if (user_id != 0 && user_id != '') {

            var url = '/utenti/' + user_id;

            $('#rimuovere_utente').modal('hide');

            $.ajax({
                url: url,
                type: "DELETE",
                data: {
                    "_token": $('meta[name="csrf-token"]').attr('content')
                },
                success: function (data) {
                    if (data == 201) {
                        $('#succes_add').hide();
                        $('#succes_update').hide();
                        $('#error_untete_delete').hide();
                        $('#succes_delete').show();
                        $('#line_' + user_id).remove();
                    } else if (data == 404) {
                        $('#succes_add').hide();
                        $('#succes_update').hide();
                        $('#succes_delete').hide();
                        $('#error_untete_delete').show();
                    }
                },
                error: function (error) {
                    console.error(error);
                }
            });
        } else {
            alert('Non puoi cancellare te stesso');
        }
    });

    $('#add_utente_btn').on('click', function () {
        $('#user_email').val('');
        $('#user_name').val('');
        $('#user_pwd').val('');
    });

    $('#aggiungi_utente_btn').on('click', function () {

        let client;
        let role = Number($('#tipo_utente').val());
        if (role === 4 || role === 5) {
            client = $('#client_utente').val();
        } else {
            client = 'none'
        }

        if ($('#user_email').val() == '' || $('#user_email').val().indexOf('@') == -1) {
            alert('Inserisci email');
        } else if ($('#user_name').val() == '') {
            alert('Inserisci nome');
        } else if ($('#user_pwd').val() == '') {
            alert('Inserisci password');
        } else {
            $.ajax({
                url: '/utenti',
                type: 'POST',
                data: {
                    "_token": $('meta[name="csrf-token"]').attr('content'),
                    "email": $('#user_email').val(),
                    "name": $('#user_name').val(),
                    "password": $('#user_pwd').val(),
                    "role": role,
                    "client": client
                },
                success: function (data) {
                    if (data.code) {
                        let code = data.message.split(':')[0];
                        if (code === "SQLSTATE[23000]") {
                            alert('Attenzione!' + '\n' + 'C\'è già un utente con la stessa email. Per favore controlla e riprova.');
                        } else {
                            let messages = data.message.split('for');
                            alert('Attenzione! \n' + messages[0]);
                        }

                    } else {
                        $('#agguingi_utente').modal('hide');
                        window.location.href = '/utenti';
                    }

                    // let utente = data.user;
                    // let utenteName = '';
                    //
                    // if (data.client) {
                    //     utenteName = data.client.company_name + ' - ' + utente.name;
                    // } else {
                    //     utenteName = utente.name;
                    // }
                    //
                    // let tipo_admin = '';
                    //
                    // if (utente.role == 1) {
                    //     tipo_admin = '<span class="tipo">Tipo: </span> <span class="tipo-role">Admin</span> ';
                    // } else if (utente.role == 2) {
                    //     tipo_admin = '<span class="tipo">Tipo: </span> <span class="tipo-role">Consulenti</span> ';
                    // } else if (utente.role == 3) {
                    //     tipo_admin = '<span class="tipo">Tipo: </span> <span class="tipo-role">Intermediari</span> ';
                    // } else if (utente.role == 4) {
                    //     tipo_admin = '<span class="tipo">Tipo: </span> <span class="tipo-role">Utenti Rifiutisicuri</span> ';
                    // } else if (utente.role == 5) {
                    //     tipo_admin = '<span class="tipo">Tipo: </span> <span class="tipo-role">Utenti Smaltimento</span> ';
                    // } else if (utente.role == 6) {
                    //     tipo_admin = '<span class="tipo">Tipo: </span> <span class="tipo-role">Utenti</span> ';
                    // }
                    //
                    // let remove_btn = "";
                    // if (auth_user.role == 1) {
                    //     if (utente.id == auth_user.id) {
                    //         remove_btn = '<div class="col_btn_rm"><a href="#" id="utente_0" class="remove_button"><i class="fa fa-times-circle-o fa-2x"></i></a></div>'
                    //     } else {
                    //         remove_btn = '<div class="col_btn_rm"><a href="#" id="utente_' + utente.id + '" class="remove_button newly_created" ><i class="fa fa-times-circle-o fa-2x"></i></a></div>'
                    //     }
                    // }
                    //
                    // $("#users_table > tbody").prepend(
                    //     '<tr id="line_' + utente.id + '" class="users_list">' +
                    //     '<td class="line_border">' +
                    //     '<div class="row">' +
                    //     '<div class="col-6"><a id="utente-edit_' + utente.id + '" href="#" class="user_name" data-toggle="modal" data-target="#edit_utente">' + utenteName + '</a></div>' +
                    //     '<div class=" col-6 remove_block">' +
                    //     '<div class="row">' +
                    //     '<div class="col-8">' +
                    //     tipo_admin +
                    //     '</div>' +
                    //     '<div class="col-4">' +
                    //     remove_btn +
                    //     ' </div>' +
                    //     ' </div>' +
                    //     '</div>' +
                    //     ' </div>' +
                    //     '</td>' +
                    //     '</tr>'
                    // );
                    // $('#succes_update').hide();
                    // $('#succes_delete').hide();
                    // $('#error_untete_delete').hide();
                    // $('#succes_add').show();
                },
                error: function (error) {
                    alert(error.toString());
                    console.error(error);
                }
            });
        }
    });

    $('body').on('click', '.user_name', function () {
        var id = this.id;
        var user_id = id.split('_')[1];

        $.ajax({
            url: '/utenti/' + user_id + '/edit',
            type: 'GET',
            data: {
                "_token": $('meta[name="csrf-token"]').attr('content'),

            },
            success: function (res) {
                let data = res;
                let utente = data.user;
                let edit_client_utente = $('#edit_client_utente');

                $('#edit_utente_id').val(utente.id);

                $('#edit_tipo_utente').val(utente.role);

                if (utente.role === 4 || utente.role === 5) {

                    let clientIds = utente.clientId;

                    if (data.clients.length > 0) {
                        clientIds = data.clients.map(client => client.id);
                    }

                    if (clientIds !== null) {
                        edit_client_utente.val(clientIds);
                    }

                    edit_client_utente.attr('disabled', false);
                } else {
                    edit_client_utente.attr('disabled', true);
                }

                $('#edit_user_email').val(utente.email);
                $('#edit_user_name').val(utente.name);

            },
            error: function (error) {
                console.error(error);
            }
        });
    });

    $('#tipo_utente').on('change', function () {
        changeAddTipoUtente();
    });

    $('#edit_tipo_utente').on('change', function () {
        changeEditTipoUtente();
    });

    function changeAddTipoUtente() {
        let client_utente = $('#client_utente');
        if (Number($('#tipo_utente').val()) === 4 || Number($('#tipo_utente').val()) === 5) {
            client_utente.attr('disabled', false);
        } else {
            client_utente.attr('disabled', true);
        }
    }

    function changeEditTipoUtente() {
        let edit_client_utente = $('#edit_client_utente');
        if (Number($('#edit_tipo_utente').val()) === 4 || Number($('#edit_tipo_utente').val()) === 5) {
            edit_client_utente.attr('disabled', false);
        } else {
            edit_client_utente.attr('disabled', true);
        }
    }

    $('#update_utente_btn').on('click', function () {
        let client;
        let role = Number($('#edit_tipo_utente').val());
        if (role === 4 || role === 5) {
            client = $('#edit_client_utente').val();
        } else {
            client = 'none'
        }
        if ($('#edit_user_email').val() == '' | $('#edit_user_email').val().indexOf('@') == -1) {
            alert('Inserisci email');
        } else if ($('#edit_user_name').val() == '') {
            alert('Inserisci nome');
        } else {
            $.ajax({
                url: '/utenti/' + $('#edit_utente_id').val(),
                type: 'POST',
                data: {
                    "_token": $('meta[name="csrf-token"]').attr('content'),
                    "_method": "PUT",
                    "email": $('#edit_user_email').val(),
                    "name": $('#edit_user_name').val(),
                    "role": role,
                    "client": client,
                },
                success: function (data) {
                    if (data.code) {
                        let code = data.message.split(':')[0];
                        if (code === "SQLSTATE[23000]") {
                            alert('Attenzione!' + '\n' + 'C\'è già un utente con la stessa email. Per favore controlla e riprova.');
                        } else {
                            let messages = data.message.split('for');
                            alert('Attenzione! \n' + messages[0]);
                        }

                    } else {
                        $('#agguingi_utente').modal('hide');
                        window.location.href = '/utenti';
                    }
                    // let utente = data.user;
                    // let line = $('#line_' + utente.id);
                    //
                    // if (data.client) {
                    //     line.find('.user_name').text(data.client.company_name + ' - ' + utente.name);
                    // } else {
                    //     line.find('.user_name').text(utente.name);
                    // }
                    //
                    // $('#succes_add').hide();
                    // $('#succes_delete').hide();
                    // $('#error_untete_delete').hide();
                    // $('#succes_update').show();
                    //
                    // if (utente.role === 1) {
                    //     line.find('.tipo-role').text('Admin');
                    // } else if (utente.role === 2) {
                    //     line.find('.tipo-role').text('Consulenti');
                    // } else if (utente.role === 3) {
                    //     line.find('.tipo-role').text('Intermediari');
                    // } else if (utente.role === 4) {
                    //     line.find('.tipo-role').text('Utenti Rifiutisicuri');
                    // } else if (utente.role === 5) {
                    //     line.find('.tipo-role').text('Utenti Smaltimento');
                    // } else if (utente.role === 6) {
                    //     line.find('.tipo-role').text('Utenti');
                    // }
                },
                error: function (error) {
                    alert(error.toString());
                    console.error(error);
                }
            });
        }
    });

    $("#classificazione_adr_popup").on('hidden.bs.modal', function (e) {
        window.location.reload();
    });
});

