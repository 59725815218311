import * as $ from 'jquery';
import 'bootstrap';

$(document).ready(function () {

    $('#tipo_utente').on('click', function () {
        if ($('#tipo_utente').val() == 'Client') {
            $('#client_utente').attr('disabled', false);
        } else {
            $('#client_utente').attr('disabled', true);
        }
    });

    // when 'are you sure' modal opens get user id
    $(document).on('click', '.remove_button', function() {

        if ($(this).hasClass('newly_created')) {
            $('#rimuovere_utente').modal('show');
            $('#succes_add').hide();
            $('#succes_update').hide();
            $('#succes_delete').hide();
            $('#error_untete_delete').hide();
        }

        var _user_to_be_removed_id = $(this).attr('id').replace('utente_', '');
        $('#rimuovere_utente_btn').data('userid', _user_to_be_removed_id);

    });

    // on close 'are you sure' popup
    $(document).on('click', '#rimuovere_anulla_btn', function() {

        $('#rimuovere_utente').modal('hide');
        $('#rimuovere_utente_btn').data('userid', '');

    });

    $(document).on('click', '#rimuovere_utente_btn', function () {

        var user_id = $('#rimuovere_utente_btn').data('userid');

        if (user_id != 0 && user_id != '') {

            var url = '/utenti/' + user_id;

            $('#rimuovere_utente').modal('hide');

            $.ajax({
                url: url,
                type: "DELETE",
                data: {
                    "_token": $('meta[name="csrf-token"]').attr('content')
                },
                success: function (data)
                {
                    if (data == 201) {
                        $('#succes_add').hide();
                        $('#succes_update').hide();
                        $('#error_untete_delete').hide();
                        $('#succes_delete').show();
                        $('#line_' + user_id).remove();
                    } else if (data == 404) {
                        $('#succes_add').hide();
                        $('#succes_update').hide();
                        $('#succes_delete').hide();
                        $('#error_untete_delete').show();
                    }
                },
                error: function (error)
                {
                    console.error(error);
                }
            });
        } else {
            alert('Non puoi cancellare te stesso');
        }
    });
    
    $('#add_utente_btn').on('click', function(){
        $('#user_email').val('');
        $('#user_name').val('');
        $('#user_pwd').val('');
    });

    $('#aggiungi_utente_btn').on('click', function () {

        var client;
        if ($('#tipo_utente').val() == 'Client') {
            client = $('#client_utente').val();
        } else {
            client = 'none'
        }

        if ($('#user_email').val() == '' || $('#user_email').val().indexOf('@') == -1) {
            alert('Inserisci email');
        } else if ($('#user_name').val() == '') {
            alert('Inserisci nome');
        } else if ($('#user_pwd').val() == '') {
            alert('Inserisci password');
        } else {
            $('#agguingi_utente').modal('hide');
            $.ajax({
                url: '/utenti',
                type: 'POST',
                data: {
                    "_token": $('meta[name="csrf-token"]').attr('content'),
                    "email": $('#user_email').val(),
                    "name": $('#user_name').val(),
                    "password": $('#user_pwd').val(),
                    "client": client
                },
                success: function (data)
                {
                    var utente = JSON.parse(data)
                    if (utente.role == 10) {
                        var tipo_admin = '<span class="tipo">Tipo: </span> Admin ';
                    } else {
                        var tipo_admin = '';
                    }
//                var index = $('#users_table >tbody >tr').length;

                    if (auth_user.role == 10) {
                        if (utente.id == auth_user.id) {
                            var remove_btn = '<div class="col_btn_rm"><a href="#" id="utente_0" class="remove_button"><i class="fa fa-times-circle-o fa-2x"></i></a></div>'
                        } else {
                            var remove_btn = '<div class="col_btn_rm"><a href="#" id="utente_' + utente.id + '" class="remove_button newly_created" ><i class="fa fa-times-circle-o fa-2x"></i></a></div>'
                        }
                    } else {
                        var remove_btn = '';
                    }

                    $("#users_table > tbody").prepend(
                            '<tr id="line_' + utente.id + '" class="users_list">' +
                            '<td class="line_border">' +
                            '<div class="row">' +
                            '<div class="col-6"><a id="utente-edit_' + utente.id + '" href="#" class="user_name" data-toggle="modal" data-target="#edit_utente">' + utente.name + '</a></div>' +
                            '<div class=" col-6 remove_block">' +
                            '<div class="row">' +
                            '<div class="col-10">' +
                            tipo_admin +
                            '</div>' +
                            '<div class="col-2">' +
                            remove_btn +
                            ' </div>' +
                            ' </div>' +
                            '</div>' +
                            ' </div>' +
                            '</td>' +
                            '</tr>'
                            );
                    $('#succes_update').hide();
                    $('#succes_delete').hide();
                    $('#error_untete_delete').hide();
                    $('#succes_add').show();
                },
                error: function (error)
                {
                    console.error(error);
                }
            });
        }
    });

    $('body').on('click', '.user_name', function () {
        var id = this.id;
        var user_id = id.split('_')[1];

        $.ajax({
            url: '/utenti/' + user_id + '/edit',
            type: 'GET',
            data: {
                "_token": $('meta[name="csrf-token"]').attr('content'),

            },
            success: function (data)
            {
                var utente = JSON.parse(data);

                $('#edit_utente_id').val(utente.id);
                if (utente.clientId == 0) {
                    $('#edit_tipo_utente').val('Operator');
                } else {
                    $('#edit_tipo_utente').val('Client');
                    $('#edit_client_utente').val(utente.clientId);

                }
                $('#edit_user_email').val(utente.email);
                $('#edit_user_name').val(utente.name);
//                $('#edit_user_pwd').val(utente.password);

                if ($('#edit_tipo_utente').val() == 'Client') {
                    $('#edit_client_utente').attr('disabled', false);
                } else {
                    $('#edit_client_utente').attr('disabled', true);
                }
            },
            error: function (error)
            {
                console.error(error);
            }
        });

    });

    $('#edit_tipo_utente').on('click', function () {
        if ($('#edit_tipo_utente').val() == 'Client') {
            $('#edit_client_utente').attr('disabled', false);
        } else {
            $('#edit_client_utente').attr('disabled', true);
        }
    });

    $('#update_utente_btn').on('click', function () {
        var client;
        if ($('#edit_tipo_utente').val() == 'Client') {
            client = $('#edit_client_utente').val();
        } else {
            client = 'none'
        }
        if ($('#edit_user_email').val() == '' | $('#edit_user_email').val().indexOf('@') == -1) {
            alert('Inserisci email');
        } else if ($('#edit_user_name').val() == '') {
            alert('Inserisci nome');
        } else {
            $('#edit_utente').modal('hide');
            $.ajax({
                url: '/utenti/' + $('#edit_utente_id').val(),
                type: 'POST',
                data: {
                    "_token": $('meta[name="csrf-token"]').attr('content'),
                    "_method": "PUT",
                    "email": $('#edit_user_email').val(),
                    "name": $('#edit_user_name').val(),
                    "client": client,
                },
                success: function (data)
                {
                    var utente = JSON.parse(data);
                    $('#line_' + utente.id).find('.user_name').text(utente.name);
                    $('#succes_add').hide();
                    $('#succes_delete').hide();
                    $('#error_untete_delete').hide();
                    $('#succes_update').show();
                },
                error: function (error)
                {
                    console.error(error);
                }
            });
        }
    });
});

