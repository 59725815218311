require('./bootstrap');

window._ = require('lodash');
window.moment = require('moment');

import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';


Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

if (typeof(window.initSelect2) == 'function') {
	window.initSelect2();
}

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// if (document.getElementById('app')) {
//     const app = new Vue({
//         el: '#app',
//     });
// }

if (document.getElementById('popupVue')) {
    const popupVue = new Vue({
        el: '#popupVue',
    });
}
