<template>
    <div class="cloud-modal-component">
        <b-modal :id="nameModal" hide-footer hide-header-close
                 @show="init"
                 @hidden="resetModal"
                 class="position-relative">
            <template #modal-title>
                <div class="title-cloud-modal">
                    Aggiungi
                </div>
            </template>
            <div class="d-block text-center">
                <div v-if="loader" class="cloud-modal-loader">
                    <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <b-form @submit="onSubmit">
                    <div class="cloud-modal-items-block cloud-modal-add-block" v-if="name !== 'Note'">
                        <div class="mr-10">
                            <div class="cloud-modal-header-items-block header-items-block-m">DOCUMENTO</div>
                            <b-form-input id="input-1" required v-model="form.document_name"></b-form-input>
                        </div>
                        <div>
                            <div class="cloud-modal-header-items-block header-items-block-m">LINK</div>
                            <b-form-input id="input-2" required v-model="form.link"></b-form-input>
                        </div>
                    </div>
                    <div class="cloud-modal-add-block" v-else>
                        <div>
                            <div class="cloud-modal-header-items-block header-items-block-m">DOCUMENTO</div>
                            <b-form-textarea
                                id="textarea"
                                v-model="form.document_name"
                                rows="3"
                            ></b-form-textarea>
                        </div>
                        <div style="width: 50%;">
                            <div class="cloud-modal-header-items-block header-items-block-m">LINK</div>
                            <b-form-input id="input-2" required v-model="form.link"></b-form-input>
                        </div>
                    </div>
                    <div class="btn-cloud-modal-component flex align-items-center justify-content-center">
                        <b-button class="btn-modal-cancel text-center border-0 annulla_documents_upload"
                                  @click="$bvModal.hide(nameModal)">
                            Annulla
                        </b-button>
                        <b-button class="btn-modal-cancel text-center border-0 annulla_documents_upload"
                                  type="submit"
                                  :disabled="disabled"
                                  style="background-color: #29B55A; margin-left: 50px">
                            Aggiungi
                        </b-button>
                    </div>
                </b-form>
            </div>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: "AddCloudModalComponent",
        props: ['parentId', 'name', 'item', 'nameModal', 'urlPfefix', 'returnMethod'],
        data() {
            return {
                loader: false,
                disabled: false,
                form: {
                    id: null,
                    document_name: null,
                    link: null
                }
            }
        },
        methods: {
            init() {
                if (this.item.id) {
                    this.form = {...this.item};
                }
            },

            onSubmit(event) {
                event.preventDefault();
                this.loader = true;
                this.disabled = true;
                if (this.item.id) {
                    this.updateDocument();
                } else {
                    this.createDocument();
                }
            },

            createDocument() {
                let params = {
                    id: this.parentId,
                    category: this.name,
                    form: this.form
                };
                axios.post('/' + this.urlPfefix + '/createCloudDocuments', params).then(res => {
                    if (res.data) {
                        this.$bvModal.hide(this.nameModal);
                        this.returnMethod();
                    }
                    this.loader = false;
                    this.disabled = false;
                }).catch(error => {
                    this.loader = false;
                    this.disabled = false;
                    alert(error);
                });
            },

            updateDocument() {
                let params = {
                    form: this.form
                };
                axios.post('/' + this.urlPfefix + '/updateCloudDocuments', params).then(res => {
                    if (res.data) {
                        this.$bvModal.hide(this.nameModal);
                        this.returnMethod();
                    }
                    this.loader = false;
                    this.disabled = false;
                }).catch(error => {
                    this.loader = false;
                    this.disabled = false;
                    alert(error);
                });
            },

            resetModal() {
                this.form.id = null;
                this.form.document_name = null;
                this.form.link = null;
            }
        },
        mounted() {

        }
    }

</script>

<style scoped>
    .mr-10 {
        margin-right: 10px;
    }

    .cloud-modal-items-block div:nth-child(1) {
        width: 30%;
    }

    .cloud-modal-items-block div:nth-child(2) {
        width: 70%;
    }

    .cloud-modal-add-block {
        border: none;
        margin-bottom: 35px;
    }
    .header-items-block-m{
        margin: 7px 0 10px;
    }
</style>
