import * as $ from 'jquery';
import 'select2';

let initSelect2;
export default initSelect2 = function () {
	let formatFunctions = {
		formatcerificate: (state)  => {
			if (!state.id) return state.text; // optgroup

			let originalOption = state.element;
			let dangerous = '';
			if (typeof($(originalOption).data('dangerous')) != 'undefined' && $(originalOption).data('dangerous') == 1) {
				dangerous = '*';
			}
			return "<span class='row'><span class='col-md-2 select-certificate-code'>" + state.id + dangerous +"</span><span class='col-md-10 select-certificate-code'>" + state.text + "</span></span>";
		},
		matchWithId: (params, data) => {
			// If there are no search terms, return all of the data
			if ($.trim(params.term) === '') {
				return data;
			}

			// Do not display the item if there is no 'text' property
			if (typeof data.text === 'undefined') {
				return null;
			}
			if (data.id=='' || data.text.search(params.term) > -1 || data.id.search(params.term) > -1) {
				var modifiedData = $.extend({}, data, true);
				return modifiedData;
			}

			return null;
		}
	}

	if ($('.select2').length) {
		$('.select2').each(function(index) {
			let data = $(this).data();
			if (typeof(data.disablesearch) != 'undefined') {
				data.minimumResultsForSearch = -1;
				delete data.disablesearch;
			}
			if (typeof(data.format) != 'undefined' && typeof(formatFunctions[data.format]) == 'function') {
				data.templateResult = formatFunctions[data.format];
				data.templateSelection = formatFunctions[data.format];
				data.matcher = formatFunctions['matchWithId'];
				data.escapeMarkup = function(m) { return m; }
				delete data.format;
			}
			$(this).select2(data);
		});
	}

	if ($('.select2Ajax').length) {
		$('.select2Ajax').each(function( index ) {
			let url = $(this).data('ajaxurl');
			$(this).select2({
				ajax: {
					url: url,
					dataType: 'json'
				}
			});
		});
	}
}
window.initSelect2 = initSelect2;