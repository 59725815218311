import * as $ from 'jquery';

export default (function () {
  // ٍSidebar Toggle
  $('.sidebar-toggle').on('click', e => {
    $('.app').toggleClass('is-collapsed');
    if ($('.app').hasClass('is-collapsed')) {
        localStorage.setItem('isCollapsed', 'true');
    } else {
        localStorage.removeItem('isCollapsed');
    }
    e.preventDefault();
  });
}());
