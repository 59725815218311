import * as $ from 'jquery';
import Swal from 'sweetalert2';

export default (function () {
    $(document).on('click', "form.delete button", function(e) {
        var _this = $(this);
        e.preventDefault();
        Swal.fire({
            title: 'Sei sicuro?', // Opération Dangereuse
            text: 'Sei sicuro di continuare ?', // Êtes-vous sûr de continuer ?
            type: 'error',
            icon: 'error',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonColor: 'null',
            cancelButtonColor: 'null',
            confirmButtonClass: 'btn-add text-center m-2',
            cancelButtonClass: 'btn-modal-cancel text-center m-2',
            confirmButtonText: 'Si certo!', // Oui, sûr
            cancelButtonText: 'Annulla', // Annuler
        }).then(res => {
            if (res.value) {
                _this.closest("form").submit();
            }
        });
    });
}())
