<template>
    <div class="cloud-modal-component">
        <b-modal :id="nameModal" hide-footer hide-header-close hide-header
                 @show="init"
                 @hidden="resetModal"
                 class="position-relative">
            <div class="d-block text-center">
                <div class="title-cloud-modal text-center">
                    <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex; font-size: 16px">
                        <span class="swal2-x-mark">
                            <span class="swal2-x-mark-line-left"></span>
                            <span class="swal2-x-mark-line-right"></span>
                        </span>
                    </div>
                    <div class="swal2-title" style="font-size: 30px">Sei sicuro?</div>
                </div>
                <div class="swal2-content" style="margin-bottom: 25px">
                    Sei sicuro di continuare ?
                </div>
                <div class="btn-cloud-modal-component flex align-items-center justify-content-center">
                    <b-button class="btn-modal-cancel text-center border-0 annulla_documents_upload"
                              @click="$bvModal.hide(nameModal)">
                        Annulla
                    </b-button>
                    <b-button class="btn-modal-cancel text-center border-0 annulla_documents_upload"
                              @click="remove"
                              :disabled="disabled"
                              style="background-color: #29B55A; margin-left: 30px">
                        Si certo!
                    </b-button>
                </div>

            </div>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: "AddCloudModalComponent",
        props: ['parentId', 'name', 'item', 'nameModal', 'urlPfefix', 'returnMethod'],
        data() {
            return {
                loader: false,
                disabled: false,
                form: {
                    id: null,
                }
            }
        },
        methods: {
            init() {
                if (this.item.id) {
                    this.form.id = this.item.id;
                }
            },

            remove() {
                this.loader = true;
                this.disabled = true;
                let params = {
                    id: this.form.id
                };
                axios.post('/' + this.urlPfefix + '/removeCloudDocuments', params).then(res => {
                    if (res.data) {
                        this.$bvModal.hide(this.nameModal);
                        this.returnMethod();
                    }
                    this.loader = false;
                    this.disabled = false;
                }).catch(error => {
                    this.loader = false;
                    this.disabled = false;
                    alert(error);
                });
            },

            resetModal() {
                this.form.id = null;
            }
        },
        mounted() {

        }
    }

</script>

<style>

    #bv-modal-documenti-delete .modal-dialog,
    #bv-modal-cloud-delete .modal-dialog,
    #bv-modal-costi-delete .modal-dialog,
    #bv-modal-note-delete .modal-dialog {
        max-width: 32em;
    }

    #bv-modal-documenti-delete .modal-content,
    #bv-modal-cloud-delete .modal-content,
    #bv-modal-costi-delete .modal-content,
    #bv-modal-note-delete .modal-content {
        max-width: 32em;
    }
</style>
